import React from 'react';
import {
  appFormUtil,
  StyledDropDown
} from 'modules';
import * as ds from './dataService';



export type SelectStateFieldProps<T extends object> = appFormUtil.SelectFieldProps<T>;

export const FC = <T extends object,> (props: SelectStateFieldProps<T>) => {
  const { data } = ds.useQuery();
  const options = data;
  return (
    <StyledDropDown.FC
      {...props.form.genFieldProps(props.fieldName)}
      options = {options}
    />
  );
};
