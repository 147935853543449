import {
  Box,
  Typography,
  Divider,
  Stack
} from '@mui/material';
import React, { ReactNode } from 'react';



export type FormSectionLayoutProps = {
  sectionName: string;
  children: ReactNode | ReactNode[];
};

export const FC = (props: FormSectionLayoutProps) => {

  return (
    <Box>
      <Typography
        variant = 'modalSectionTitle'
      >
        {props.sectionName}
      </Typography>
      <Box
        paddingY = '.75rem'
      >
        <Divider
          variant = 'fullWidth'
        />
      </Box>
      {props.children}
    </Box>
  );
};
