import React from 'react';


import { ReactComponent as ContinueIcon } from '../../../../assets/Scenario/Continue.svg';
import { ReactComponent as ReturnIcon } from '../../../../assets/Scenario/Return.svg';
import { SvgIconProps } from '@mui/material';


export type IconType =
  'CONTINUE' |
  'RETURN';



const __icon2iconType: Record<IconType, React.ReactElement> = {
  CONTINUE: <ContinueIcon />,
  RETURN: <ReturnIcon />
};


export type MediumCustomIconProps = {
  icon: IconType;
};

export const FC = (props: MediumCustomIconProps & SvgIconProps) => {

  const Res = __icon2iconType[props.icon];
  return (
    <Res.type {...props} />
  );
};
