import { RemoveRedEye } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  Typography
} from '@mui/material';
import React, { useState } from 'react';



export type StyledInputTextFieldProps = {
  validationErrorMessage?: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

export const FC = (props: StyledInputTextFieldProps & InputProps) => {
  const [
    showPassword,
    setShowPassword
  ] = useState(false);
  return (
    <FormControl
      fullWidth
      required = {props.required}
      error = {props.validationErrorMessage && props.validationErrorMessage.length > 1 ? true : false}
    >
      <FormLabel>
        <Typography variant = 'formLabel' >{props.label}</Typography>
      </FormLabel>

      <Input
        fullWidth
        multiline = {props.minRows ? true : false}
        minRows = {props.minRows ? props.minRows : 1}
        required = {props.required}
        value = {props.value}
        name = {props.name}
        type = {showPassword ? 'text' : props.type}
        endAdornment = {
          props.type === 'password'
            ? <RemoveRedEye onClick = {() => setShowPassword(prev => !prev)} />
            : undefined
        }
        onChange = {
          e => {
            props.onChange(e);
          }
        }
      />{
        props.validationErrorMessage && props.validationErrorMessage.length > 1
          ? (
            <FormHelperText variant = 'standard'>
              {props.validationErrorMessage}
            </FormHelperText>
          )
          : (
            <>
            </>
          )
      }
    </FormControl>
  );
};
