import {
  styled,
  TableCell,
  TableRow
} from '@mui/material';
import React from 'react';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export type CountyRatesTableRowProps = {
  values: string[];
};

export const FC = (props: CountyRatesTableRowProps) => {

  return (
    <StyledTableRow>
      {props.values.map((cell, i) => {

        return (
          <TableCell
            size = 'small'
            key = {`${props.values[0]}-${i}`}
            variant = {i === 0 ? 'bold' : 'body'}
          >
            {cell}
          </TableCell>
        );

      })}
    </StyledTableRow>
  );
};
