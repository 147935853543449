import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** String representation of an amount of United States dollars. */
  CurrencyUsd: { input: any; output: any; }
  /** The ID of a cursor in a page. */
  Cursor: { input: any; output: any; }
  /** String representation of an arbitrary-precision number. */
  Decimal: { input: any; output: any; }
  /** A base-64 encoded file sent as input. */
  EncodedFileInput: { input: string; output: string; }
  /** A base-64 encoded file sent as output. */
  EncodedFileOutput: { input: any; output: any; }
  IsoDate: { input: any; output: any; }
  /** An object in JSON format. */
  Object: { input: any; output: any; }
  /** An ISO string representation of a timestamp. */
  Timestamp: { input: string; output: string; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
  state: State;
  street1: Scalars['String']['output'];
  street2: Scalars['String']['output'];
};

/** A file uploaded to the API. File contents is returned from the API via the `file/:id` endpoint. */
export type ApiFile = {
  __typename?: 'ApiFile';
  createdTimestamp: Scalars['Timestamp']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sizeBytes: Scalars['Int']['output'];
  url: Scalars['String']['output'];
  urlExpiry: Scalars['Timestamp']['output'];
};

export type AuthenticationStatus =
  | 'confirmSignUp'
  | 'confirmationCodeRequired'
  | 'registrationRequired'
  | 'signedIn'
  | 'signedOut';

export type ClientAuthorizationStatus = {
  __typename?: 'ClientAuthorizationStatus';
  accessToken: Scalars['String']['output'];
  authError?: Maybe<Scalars['String']['output']>;
  authStatus: AuthenticationStatus;
  isSuperAdmin: Scalars['Boolean']['output'];
  loggedInUserId: Scalars['String']['output'];
};

export type County = {
  __typename?: 'County';
  fipsCode: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  programs: Array<CountyProgram>;
  state: State;
  svgPath: Scalars['String']['output'];
};

export type CountyProgram = {
  __typename?: 'CountyProgram';
  data: Array<PracticeDataPoint>;
  practice: Practice;
};

export type CreateFileReservationInput = {
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  sizeBytes: Scalars['Int']['input'];
};

export type EmailScenario_Input = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  recepientEmail: Scalars['String']['input'];
};

export type FileAsString = {
  __typename?: 'FileAsString';
  data?: Maybe<Scalars['String']['output']>;
};

export type FileReservation = {
  __typename?: 'FileReservation';
  apiFile?: Maybe<ApiFile>;
  id: Scalars['ID']['output'];
  signedUploadUrl: Scalars['String']['output'];
};

export type InternalRequestPriorityStatus =
  | 'expedited'
  | 'low'
  | 'regular';

export type Invite = {
  __typename?: 'Invite';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createFileReservation?: Maybe<FileReservation>;
  emailScenario?: Maybe<Scenario>;
  finalizeFileReservation?: Maybe<FileReservation>;
  saveScenario?: Maybe<Scenario>;
  siteUser_register?: Maybe<SiteUserFull>;
  siteUser_terminateAccount: Scalars['Boolean']['output'];
};


export type MutationCreateFileReservationArgs = {
  input: CreateFileReservationInput;
};


export type MutationEmailScenarioArgs = {
  input: EmailScenario_Input;
};


export type MutationFinalizeFileReservationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSaveScenarioArgs = {
  input: SaveScenario_Input;
};


export type MutationSiteUser_RegisterArgs = {
  input: SiteUser_RegisterInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type Organization = Node & {
  __typename?: 'Organization';
  address: Address;
  contactPerson: Person;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Page = {
  nodes: Array<Node>;
  pageInfo: PageInfo;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  nextPage?: Maybe<Scalars['Cursor']['output']>;
  pages: Array<Scalars['Cursor']['output']>;
  previousPage?: Maybe<Scalars['Cursor']['output']>;
  totalRows: Scalars['Int']['output'];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type Person = {
  __typename?: 'Person';
  createdTimestamp: Scalars['Timestamp']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  preferredEmail: Scalars['String']['output'];
};

export type Practice = Node & {
  __typename?: 'Practice';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type PracticeAnnualData_FilterInput = {
  practiceIds: Array<Scalars['ID']['input']>;
  years: Array<Scalars['Int']['input']>;
};

export type PracticeDataPoint = {
  __typename?: 'PracticeDataPoint';
  acres: Scalars['Float']['output'];
  rentalRate: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type ProjectStatus =
  | 'active'
  | 'archived'
  | 'inProgress';

export type Query = {
  __typename?: 'Query';
  authQuery: ClientAuthorizationStatus;
  counties: Array<County>;
  downLoadScenarioCsv?: Maybe<FileAsString>;
  isSuperAdmin: Scalars['Boolean']['output'];
  loggedInUser?: Maybe<SiteUserFull>;
  scenario?: Maybe<Scenario>;
  scenarios: ScenarioPage;
  state?: Maybe<State>;
  states: Array<State>;
};


export type QueryAuthQueryArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCountiesArgs = {
  options: Query_Counties_OptionsInput;
};


export type QueryDownLoadScenarioCsvArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScenarioArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScenariosArgs = {
  input: Query_Scenario_OptionsInput;
};


export type QueryStateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStatesArgs = {
  options: Query_States_OptionsInput;
};

export type Query_Counties_FilterInput = {
  countyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  stateId?: InputMaybe<Scalars['String']['input']>;
};

export type Query_Counties_OptionsInput = {
  filter?: InputMaybe<Query_Counties_FilterInput>;
};

export type Query_Scenario_FilterInput = {
  dateCreated?: InputMaybe<Scalars['Timestamp']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type Query_Scenario_OptionsInput = {
  filter?: InputMaybe<Query_Scenario_FilterInput>;
};

export type Query_States_FilterInput = {
  stateAbbreviations?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Query_States_OptionsInput = {
  filter?: InputMaybe<Query_States_FilterInput>;
};

export type RequestStatus =
  | 'complete'
  | 'pending';

export type SaveAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  usStateAbbreviation?: InputMaybe<Scalars['String']['input']>;
};

export type SaveScenario_Input = {
  counties: Array<Scalars['String']['input']>;
  humanReadableID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type Scenario = Node & {
  __typename?: 'Scenario';
  counties: Array<County>;
  dateCreated: Scalars['String']['output'];
  humanReadableID: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  report: ApiFile;
  state: State;
};

export type ScenarioPage = Page & {
  __typename?: 'ScenarioPage';
  nodes: Array<Scenario>;
  pageInfo: PageInfo;
};

export type SiteUserFull = {
  __typename?: 'SiteUserFull';
  createdTimestamp: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  person: Person;
};

export type SiteUser_RegisterInput = {
  inviteCode?: InputMaybe<Scalars['ID']['input']>;
  preferredEmail: Scalars['String']['input'];
};

export type SortDirection =
  | 'ascending'
  | 'descending';

export type State = {
  __typename?: 'State';
  abbreviation: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PageInfoFieldsFragment = { __typename?: 'PageInfo', nextPage?: any | null, pages: Array<any>, previousPage?: any | null, totalRows: number };

export type ApiFileFieldsFragment = { __typename?: 'ApiFile', id: string, url: string, fileName: string, sizeBytes: number, urlExpiry: string };

export type AddressFieldsFragment = { __typename?: 'Address', id: string, street1: string, street2: string, city: string, postalCode: string, state: { __typename?: 'State', id: string, name: string, abbreviation: string } };

export type PersonFieldsFragment = { __typename?: 'Person', id: string, firstName: string, lastName: string, preferredEmail: string, phoneNumber: string };

export type GetCountiesForStateQueryVariables = Exact<{
  options: Query_Counties_OptionsInput;
}>;


export type GetCountiesForStateQuery = { __typename?: 'Query', counties: Array<{ __typename?: 'County', id: string, fipsCode: number, name: string, svgPath: string }> };

export type GetCountiesForStateMapQueryVariables = Exact<{
  options: Query_Counties_OptionsInput;
}>;


export type GetCountiesForStateMapQuery = { __typename?: 'Query', counties: Array<{ __typename?: 'County', name: string, id: string, fipsCode: number, svgPath: string }> };

export type GetAllStatesQueryVariables = Exact<{
  options: Query_States_OptionsInput;
  countyOptions: Query_Counties_OptionsInput;
}>;


export type GetAllStatesQuery = { __typename?: 'Query', states: Array<{ __typename?: 'State', id: string, name: string, abbreviation: string }>, counties: Array<{ __typename?: 'County', name: string, id: string, fipsCode: number, svgPath: string }> };

export type EmailScenarioMutationVariables = Exact<{
  input: EmailScenario_Input;
}>;


export type EmailScenarioMutation = { __typename?: 'Mutation', emailScenario?: { __typename?: 'Scenario', id: string } | null };

export type GetAllScenariosQueryVariables = Exact<{
  options: Query_Scenario_OptionsInput;
}>;


export type GetAllScenariosQuery = { __typename?: 'Query', scenarios: { __typename?: 'ScenarioPage', pageInfo: { __typename?: 'PageInfo', nextPage?: any | null, pages: Array<any>, previousPage?: any | null, totalRows: number }, nodes: Array<{ __typename?: 'Scenario', id: string, name: string, message: string, humanReadableID: string, dateCreated: string, state: { __typename?: 'State', id: string, abbreviation: string }, counties: Array<{ __typename?: 'County', id: string, name: string }> }> } };

export type SaveScenarioMutationVariables = Exact<{
  input: SaveScenario_Input;
}>;


export type SaveScenarioMutation = { __typename?: 'Mutation', saveScenario?: { __typename?: 'Scenario', id: string, name: string, message: string, humanReadableID: string, dateCreated: string, state: { __typename?: 'State', id: string, name: string, abbreviation: string }, counties: Array<{ __typename?: 'County', id: string, name: string, fipsCode: number, programs: Array<{ __typename?: 'CountyProgram', data: Array<{ __typename?: 'PracticeDataPoint', acres: number, rentalRate: number, year: number }>, practice: { __typename?: 'Practice', code: string, id: string, title: string } }> }> } | null };

export type SaveScenario_FragFragment = { __typename?: 'Scenario', id: string, name: string, message: string, humanReadableID: string, dateCreated: string, state: { __typename?: 'State', id: string, name: string }, counties: Array<{ __typename?: 'County', id: string, name: string }> };

export type GetScenarioDataAsCsvQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetScenarioDataAsCsvQuery = { __typename?: 'Query', downLoadScenarioCsv?: { __typename?: 'FileAsString', data?: string | null } | null };

export type GetScenarioDetailQueryVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
}>;


export type GetScenarioDetailQuery = { __typename?: 'Query', scenario?: { __typename?: 'Scenario', id: string, humanReadableID: string, name: string, message: string, dateCreated: string, state: { __typename?: 'State', id: string, name: string, abbreviation: string }, counties: Array<{ __typename?: 'County', id: string, name: string, fipsCode: number, programs: Array<{ __typename?: 'CountyProgram', data: Array<{ __typename?: 'PracticeDataPoint', acres: number, rentalRate: number, year: number }>, practice: { __typename?: 'Practice', code: string, id: string, title: string } }> }> } | null };

export type GetScenarioDetail_QueryFragFragment = { __typename?: 'Scenario', id: string, humanReadableID: string, name: string, message: string, dateCreated: string, state: { __typename?: 'State', id: string, name: string, abbreviation: string }, counties: Array<{ __typename?: 'County', id: string, name: string, fipsCode: number, programs: Array<{ __typename?: 'CountyProgram', data: Array<{ __typename?: 'PracticeDataPoint', acres: number, rentalRate: number, year: number }>, practice: { __typename?: 'Practice', code: string, id: string, title: string } }> }> };

export type County_FragFragment = { __typename?: 'County', id: string, name: string, fipsCode: number, programs: Array<{ __typename?: 'CountyProgram', data: Array<{ __typename?: 'PracticeDataPoint', acres: number, rentalRate: number, year: number }>, practice: { __typename?: 'Practice', code: string, id: string, title: string } }> };

export const PageInfoFieldsFragmentDoc = gql`
    fragment pageInfoFields on PageInfo {
  nextPage
  pages
  previousPage
  totalRows
}
    `;
export const ApiFileFieldsFragmentDoc = gql`
    fragment apiFileFields on ApiFile {
  id
  url
  fileName
  sizeBytes
  urlExpiry
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment addressFields on Address {
  id
  street1
  street2
  city
  state {
    id
    name
    abbreviation
  }
  postalCode
}
    `;
export const PersonFieldsFragmentDoc = gql`
    fragment personFields on Person {
  id
  firstName
  lastName
  preferredEmail
  phoneNumber
}
    `;
export const SaveScenario_FragFragmentDoc = gql`
    fragment SaveScenario_Frag on Scenario {
  id
  name
  message
  humanReadableID
  dateCreated
  state {
    id
    name
  }
  counties {
    id
    name
  }
}
    `;
export const County_FragFragmentDoc = gql`
    fragment County_Frag on County {
  id
  name
  fipsCode
  programs {
    data {
      acres
      rentalRate
      year
    }
    practice {
      code
      id
      title
    }
  }
}
    `;
export const GetScenarioDetail_QueryFragFragmentDoc = gql`
    fragment GetScenarioDetail_QueryFrag on Scenario {
  id
  humanReadableID
  name
  message
  dateCreated
  state {
    id
    name
    abbreviation
  }
  counties {
    ...County_Frag
  }
}
    ${County_FragFragmentDoc}`;
export const GetCountiesForStateDocument = gql`
    query GetCountiesForState($options: Query_Counties_OptionsInput!) {
  counties(options: $options) {
    id
    fipsCode
    name
    svgPath
  }
}
    `;

/**
 * __useGetCountiesForStateQuery__
 *
 * To run a query within a React component, call `useGetCountiesForStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountiesForStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountiesForStateQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCountiesForStateQuery(baseOptions: Apollo.QueryHookOptions<GetCountiesForStateQuery, GetCountiesForStateQueryVariables> & ({ variables: GetCountiesForStateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountiesForStateQuery, GetCountiesForStateQueryVariables>(GetCountiesForStateDocument, options);
      }
export function useGetCountiesForStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountiesForStateQuery, GetCountiesForStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountiesForStateQuery, GetCountiesForStateQueryVariables>(GetCountiesForStateDocument, options);
        }
export function useGetCountiesForStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCountiesForStateQuery, GetCountiesForStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCountiesForStateQuery, GetCountiesForStateQueryVariables>(GetCountiesForStateDocument, options);
        }
export type GetCountiesForStateQueryHookResult = ReturnType<typeof useGetCountiesForStateQuery>;
export type GetCountiesForStateLazyQueryHookResult = ReturnType<typeof useGetCountiesForStateLazyQuery>;
export type GetCountiesForStateSuspenseQueryHookResult = ReturnType<typeof useGetCountiesForStateSuspenseQuery>;
export type GetCountiesForStateQueryResult = Apollo.QueryResult<GetCountiesForStateQuery, GetCountiesForStateQueryVariables>;
export const GetCountiesForStateMapDocument = gql`
    query GetCountiesForStateMap($options: Query_Counties_OptionsInput!) {
  counties(options: $options) {
    name
    id
    fipsCode
    svgPath
  }
}
    `;

/**
 * __useGetCountiesForStateMapQuery__
 *
 * To run a query within a React component, call `useGetCountiesForStateMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountiesForStateMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountiesForStateMapQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCountiesForStateMapQuery(baseOptions: Apollo.QueryHookOptions<GetCountiesForStateMapQuery, GetCountiesForStateMapQueryVariables> & ({ variables: GetCountiesForStateMapQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountiesForStateMapQuery, GetCountiesForStateMapQueryVariables>(GetCountiesForStateMapDocument, options);
      }
export function useGetCountiesForStateMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountiesForStateMapQuery, GetCountiesForStateMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountiesForStateMapQuery, GetCountiesForStateMapQueryVariables>(GetCountiesForStateMapDocument, options);
        }
export function useGetCountiesForStateMapSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCountiesForStateMapQuery, GetCountiesForStateMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCountiesForStateMapQuery, GetCountiesForStateMapQueryVariables>(GetCountiesForStateMapDocument, options);
        }
export type GetCountiesForStateMapQueryHookResult = ReturnType<typeof useGetCountiesForStateMapQuery>;
export type GetCountiesForStateMapLazyQueryHookResult = ReturnType<typeof useGetCountiesForStateMapLazyQuery>;
export type GetCountiesForStateMapSuspenseQueryHookResult = ReturnType<typeof useGetCountiesForStateMapSuspenseQuery>;
export type GetCountiesForStateMapQueryResult = Apollo.QueryResult<GetCountiesForStateMapQuery, GetCountiesForStateMapQueryVariables>;
export const GetAllStatesDocument = gql`
    query GetAllStates($options: Query_States_OptionsInput!, $countyOptions: Query_Counties_OptionsInput!) {
  states(options: $options) {
    id
    name
    abbreviation
  }
  counties(options: $countyOptions) {
    name
    id
    fipsCode
    svgPath
  }
}
    `;

/**
 * __useGetAllStatesQuery__
 *
 * To run a query within a React component, call `useGetAllStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStatesQuery({
 *   variables: {
 *      options: // value for 'options'
 *      countyOptions: // value for 'countyOptions'
 *   },
 * });
 */
export function useGetAllStatesQuery(baseOptions: Apollo.QueryHookOptions<GetAllStatesQuery, GetAllStatesQueryVariables> & ({ variables: GetAllStatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStatesQuery, GetAllStatesQueryVariables>(GetAllStatesDocument, options);
      }
export function useGetAllStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStatesQuery, GetAllStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStatesQuery, GetAllStatesQueryVariables>(GetAllStatesDocument, options);
        }
export function useGetAllStatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllStatesQuery, GetAllStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllStatesQuery, GetAllStatesQueryVariables>(GetAllStatesDocument, options);
        }
export type GetAllStatesQueryHookResult = ReturnType<typeof useGetAllStatesQuery>;
export type GetAllStatesLazyQueryHookResult = ReturnType<typeof useGetAllStatesLazyQuery>;
export type GetAllStatesSuspenseQueryHookResult = ReturnType<typeof useGetAllStatesSuspenseQuery>;
export type GetAllStatesQueryResult = Apollo.QueryResult<GetAllStatesQuery, GetAllStatesQueryVariables>;
export const EmailScenarioDocument = gql`
    mutation EmailScenario($input: EmailScenario_Input!) {
  emailScenario(input: $input) {
    id
  }
}
    `;
export type EmailScenarioMutationFn = Apollo.MutationFunction<EmailScenarioMutation, EmailScenarioMutationVariables>;

/**
 * __useEmailScenarioMutation__
 *
 * To run a mutation, you first call `useEmailScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailScenarioMutation, { data, loading, error }] = useEmailScenarioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailScenarioMutation(baseOptions?: Apollo.MutationHookOptions<EmailScenarioMutation, EmailScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailScenarioMutation, EmailScenarioMutationVariables>(EmailScenarioDocument, options);
      }
export type EmailScenarioMutationHookResult = ReturnType<typeof useEmailScenarioMutation>;
export type EmailScenarioMutationResult = Apollo.MutationResult<EmailScenarioMutation>;
export type EmailScenarioMutationOptions = Apollo.BaseMutationOptions<EmailScenarioMutation, EmailScenarioMutationVariables>;
export const GetAllScenariosDocument = gql`
    query GetAllScenarios($options: Query_Scenario_OptionsInput!) {
  scenarios(input: $options) {
    pageInfo {
      ...pageInfoFields
    }
    nodes {
      id
      name
      message
      humanReadableID
      dateCreated
      state {
        id
        abbreviation
      }
      counties {
        id
        name
      }
    }
  }
}
    ${PageInfoFieldsFragmentDoc}`;

/**
 * __useGetAllScenariosQuery__
 *
 * To run a query within a React component, call `useGetAllScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllScenariosQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetAllScenariosQuery(baseOptions: Apollo.QueryHookOptions<GetAllScenariosQuery, GetAllScenariosQueryVariables> & ({ variables: GetAllScenariosQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllScenariosQuery, GetAllScenariosQueryVariables>(GetAllScenariosDocument, options);
      }
export function useGetAllScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllScenariosQuery, GetAllScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllScenariosQuery, GetAllScenariosQueryVariables>(GetAllScenariosDocument, options);
        }
export function useGetAllScenariosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllScenariosQuery, GetAllScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllScenariosQuery, GetAllScenariosQueryVariables>(GetAllScenariosDocument, options);
        }
export type GetAllScenariosQueryHookResult = ReturnType<typeof useGetAllScenariosQuery>;
export type GetAllScenariosLazyQueryHookResult = ReturnType<typeof useGetAllScenariosLazyQuery>;
export type GetAllScenariosSuspenseQueryHookResult = ReturnType<typeof useGetAllScenariosSuspenseQuery>;
export type GetAllScenariosQueryResult = Apollo.QueryResult<GetAllScenariosQuery, GetAllScenariosQueryVariables>;
export const SaveScenarioDocument = gql`
    mutation SaveScenario($input: SaveScenario_Input!) {
  saveScenario(input: $input) {
    ...SaveScenario_Frag
    ...GetScenarioDetail_QueryFrag
  }
}
    ${SaveScenario_FragFragmentDoc}
${GetScenarioDetail_QueryFragFragmentDoc}`;
export type SaveScenarioMutationFn = Apollo.MutationFunction<SaveScenarioMutation, SaveScenarioMutationVariables>;

/**
 * __useSaveScenarioMutation__
 *
 * To run a mutation, you first call `useSaveScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveScenarioMutation, { data, loading, error }] = useSaveScenarioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveScenarioMutation(baseOptions?: Apollo.MutationHookOptions<SaveScenarioMutation, SaveScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveScenarioMutation, SaveScenarioMutationVariables>(SaveScenarioDocument, options);
      }
export type SaveScenarioMutationHookResult = ReturnType<typeof useSaveScenarioMutation>;
export type SaveScenarioMutationResult = Apollo.MutationResult<SaveScenarioMutation>;
export type SaveScenarioMutationOptions = Apollo.BaseMutationOptions<SaveScenarioMutation, SaveScenarioMutationVariables>;
export const GetScenarioDataAsCsvDocument = gql`
    query GetScenarioDataAsCsv($id: ID!) {
  downLoadScenarioCsv(id: $id) {
    data
  }
}
    `;

/**
 * __useGetScenarioDataAsCsvQuery__
 *
 * To run a query within a React component, call `useGetScenarioDataAsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioDataAsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioDataAsCsvQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScenarioDataAsCsvQuery(baseOptions: Apollo.QueryHookOptions<GetScenarioDataAsCsvQuery, GetScenarioDataAsCsvQueryVariables> & ({ variables: GetScenarioDataAsCsvQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioDataAsCsvQuery, GetScenarioDataAsCsvQueryVariables>(GetScenarioDataAsCsvDocument, options);
      }
export function useGetScenarioDataAsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioDataAsCsvQuery, GetScenarioDataAsCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioDataAsCsvQuery, GetScenarioDataAsCsvQueryVariables>(GetScenarioDataAsCsvDocument, options);
        }
export function useGetScenarioDataAsCsvSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScenarioDataAsCsvQuery, GetScenarioDataAsCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScenarioDataAsCsvQuery, GetScenarioDataAsCsvQueryVariables>(GetScenarioDataAsCsvDocument, options);
        }
export type GetScenarioDataAsCsvQueryHookResult = ReturnType<typeof useGetScenarioDataAsCsvQuery>;
export type GetScenarioDataAsCsvLazyQueryHookResult = ReturnType<typeof useGetScenarioDataAsCsvLazyQuery>;
export type GetScenarioDataAsCsvSuspenseQueryHookResult = ReturnType<typeof useGetScenarioDataAsCsvSuspenseQuery>;
export type GetScenarioDataAsCsvQueryResult = Apollo.QueryResult<GetScenarioDataAsCsvQuery, GetScenarioDataAsCsvQueryVariables>;
export const GetScenarioDetailDocument = gql`
    query GetScenarioDetail($scenarioId: ID!) {
  scenario(id: $scenarioId) {
    ...GetScenarioDetail_QueryFrag
  }
}
    ${GetScenarioDetail_QueryFragFragmentDoc}`;

/**
 * __useGetScenarioDetailQuery__
 *
 * To run a query within a React component, call `useGetScenarioDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioDetailQuery({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useGetScenarioDetailQuery(baseOptions: Apollo.QueryHookOptions<GetScenarioDetailQuery, GetScenarioDetailQueryVariables> & ({ variables: GetScenarioDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>(GetScenarioDetailDocument, options);
      }
export function useGetScenarioDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>(GetScenarioDetailDocument, options);
        }
export function useGetScenarioDetailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>(GetScenarioDetailDocument, options);
        }
export type GetScenarioDetailQueryHookResult = ReturnType<typeof useGetScenarioDetailQuery>;
export type GetScenarioDetailLazyQueryHookResult = ReturnType<typeof useGetScenarioDetailLazyQuery>;
export type GetScenarioDetailSuspenseQueryHookResult = ReturnType<typeof useGetScenarioDetailSuspenseQuery>;
export type GetScenarioDetailQueryResult = Apollo.QueryResult<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "Organization",
      "Practice",
      "Scenario"
    ],
    "Page": [
      "ScenarioPage"
    ]
  }
};
      export default result;
    