export interface IConfig {
  apiGraphQlUrl: string;
  apiFileUrl: string;
  ignoreAccessToken: boolean;
  appName: string;
  envName: string;
}

export function getConfig(): IConfig {
  return {
    apiGraphQlUrl: process.env.REACT_APP_API_GRAPHQL_URL ?? '',
    apiFileUrl: process.env.REACT_APP_API_FILE_URL ?? '',
    ignoreAccessToken: process.env.REACT_APP_IGNORE_ACCESS_TOKEN === 'true',
    appName: process.env.REACT_APP_APP_NAME ?? '',
    envName: process.env.REACT_APP_ENV_NAME ?? ''
  };
}
