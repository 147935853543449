import React from 'react';
import { RouteObject } from 'react-router-dom';
import { AdminLayout } from './AdminLayout';
import { ScenariosActivity } from './ScenariosActivity/ScenariosActivity';
import { AdminsActivity } from './AdminsActivity/AdminsActivity';


export const root: RouteObject = {
  path: 'admin',
  element: <AdminLayout />,
  children: [
    {
      path: 'scenarios',
      element: <ScenariosActivity />
    },
    {
      path: 'admins',
      element: <AdminsActivity />
    }
  ]
};
