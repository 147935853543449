import React from 'react';
import * as ds from './dataService';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import {
  ScenariosTableRow,
  UserTableRow
} from 'modules/components/layer23';


export type UserTableProps = {};

export const FC = (props: UserTableProps) => {
  const { data } = ds.useQuery();

  const headers = [
    'name',
    'email',
    'phone',
    'date created'
  ];
  return (
    <TableContainer>
      <Table
        size = 'small'
      >
        <TableHead>
          <TableRow>
            {headers.map((cell, i) => (
              <TableCell
                size = 'medium'
                key = {i}
                variant = 'head'
              >
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((node, i) => (
              <UserTableRow.FC
                key = {i}
                {...node}
              />
            ))
          }

        </TableBody>
      </Table>
    </TableContainer>

  );
};
