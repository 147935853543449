
import {
  Box,
  Button,
  useTheme
} from '@mui/material';
import {
  CustomIcons,
  ScenarioCountiesForm,
  ScenarioDetailsForm
} from 'modules';


import {
  appFormUtil,
  useMobileView
} from 'modules/common';
import React from 'react';
import * as ds from './dataService';
import { useNavigate } from 'react-router-dom';



export type CreateScenarioSectionProps = {};

export const FC = (props: CreateScenarioSectionProps) => {
  const navigate = useNavigate();
  const isMobile = useMobileView.useMobileView();

  const theme = useTheme();

  const { initialState } = ds.useQuery();
  const [fnSaveScenario] = ds.useSaveScenario();

  const { form } = ds.useQuery();
  const { formState, dispatch } = form;

  const handleSubmit = async () => {
    const result = await fnSaveScenario({
      // id: formState.id,
      state: formState.state,
      counties: formState.counties,
      name: formState.name,
      message: formState.message
    });
    return result;
  };

  const handleNext = async (e: React.FormEvent) => {
    e.preventDefault();
    switch (formState.pageIndex) {
      case 1:
        dispatch({
          type: 'UPDATE',
          payload: {
            property: 'pageIndex',
            value: formState.pageIndex + 1
          }
        });
        break;
      case 2:
        dispatch({
          type: 'UPDATE',
          payload: {
            property: 'pageIndex',
            value: formState.pageIndex + 1
          }
        });
        const res = await handleSubmit();
        navigate(`../${res.data?.saveScenario?.id}`);


        break;

      default:
        break;
    }
    if (formState.pageIndex !== 3) {

      dispatch({
        type: 'UPDATE',
        payload: {
          property: 'pageIndex',
          value: formState.pageIndex + 1
        }
      });
    } else {
      dispatch({
        type: 'RESET'

      });
    }
  };

  const handlePrev = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch({
      type: 'UPDATE',
      payload: {
        property: 'pageIndex',
        value: formState.pageIndex - 1
      }
    });
  };

  return (
    <Box
      alignItems = 'center'
      paddingX = {isMobile ? '.5rem' : '10rem'}
    >
      <Box
        component = 'form'
        onSubmit = {e => {
          e.preventDefault();
          if (formState.pageIndex !== 2) {
            return handleNext(e);
          } else {
            return handleNext(e);
            // return handleSubmit();
          }
        }}
      >
        {formState.pageIndex === 1 && (
          <ScenarioDetailsForm.FC
            form = {form}
            fields = {[
              'name',
              'message'
            ]}
            pageIndex = 'pageIndex'
          />
        )}
        {formState.pageIndex === 2 && (
          <ScenarioCountiesForm.FC
            form = {form}
            fields = {[
              'state',
              'counties'
            ]}
            pageIndex = 'pageIndex'
            stateId = {form.formState.state}
          />
        )}

        <Box
          display = 'flex'
          flexDirection = 'row'
          columnGap = '.75rem'
          paddingTop = '3rem'
        >
          {(formState.pageIndex > 1 && formState.pageIndex !== 3) && (
            <Button
              onClick = {e => handlePrev(e)}
              startIcon = {(
                <CustomIcons.medium.FC
                  icon = 'RETURN'
                  stroke = {theme.palette.primary.main}
                />
              )}
              variant = 'outlined'
              color = 'primary'
            >
              Return
            </Button>
          )}

          <Button
            startIcon = {formState.pageIndex !== 3 && (
              <CustomIcons.medium.FC
                icon = 'CONTINUE'
                stroke = 'white'
              />
            )}

            fullWidth
            type = 'submit'
            variant = 'contained'
            color = 'primary'
          >
            {formState.pageIndex !== 3 ? 'Continue' : 'Create another Scenario'}
          </Button>
        </Box>
      </Box>
    </Box>

  );
};
