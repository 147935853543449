import { MoreHoriz } from '@mui/icons-material';
import {
  TableCell,
  TableRow
} from '@mui/material';
import React from 'react';



export type ScenariosTableRowProps = {
  id: string;
  name: string;
  state: string;
  description: string;
  dateCreated: string;
  humanReadableId: string;
  counties: string[];
};

export const FC = (props: ScenariosTableRowProps) => {
  const keys: (keyof ScenariosTableRowProps)[] = [
    'name',
    'humanReadableId',
    'state',
    'counties',
    'description',
    'dateCreated'
  ];
  return (
    <TableRow
      key = {props.id}
    >
      {keys.map((k, i) => (
        <TableCell
          key = {`${i}`}
          variant = 'body'
        >
          {k === 'counties' ? props[k].join(', ') : props[k]}
        </TableCell>
      ))}
      {/* <TableCell
        variant = 'body'
      >
        <MoreHoriz />
      </TableCell> */}
    </TableRow>
  );
};
