import {
  TableCell,
  TableRow
} from '@mui/material';
import React from 'react';



export type UserTableRowProps = {
  id: string;
  fullName: string;
  profileImg: string;
  email: string;
  phone: string;
  accountCreatedDate: string;
};

export const FC = (props: UserTableRowProps) => {
  const keys: (keyof UserTableRowProps)[] = [
    'fullName',
    'email',
    'phone',
    'accountCreatedDate'

  ];

  const cells = keys.map((cell, i) => (
    <TableCell
      key = {`usercell ${i}-${props[cell]}`}
      variant = 'body'
    >
      {props[cell]}
    </TableCell>
  ));

  return (
    <TableRow>
      {cells}
    </TableRow>
  );
};
