import {
  DocumentNode,
  MutationQueryReducer,
  MutationQueryReducersMap
} from '@apollo/client';

import { Kind } from 'graphql';



export function genUpdateQueryMap<TQueryData extends Record<string, unknown>, TMutationResult>(
  node: DocumentNode,
  fnUpdatePrevState: (data: TQueryData, result: Parameters<MutationQueryReducer<TMutationResult>>[1]) => TQueryData
): MutationQueryReducersMap<TMutationResult> {
  return {
    [__genName(node)](prev, options) {
      return fnUpdatePrevState(prev as TQueryData, options);
    }
  };
}



function __genName(node: DocumentNode) {
  const opDef = node.definitions.find(def => def.kind === 'OperationDefinition');
  if (opDef && opDef.kind === Kind.OPERATION_DEFINITION && opDef.name?.kind === Kind.NAME) {
    return opDef.name.value;

  } else {
    throw new Error('COULD_NOT_FIND_NAME');
  }
}
