import React from 'react';



export type SmallCustomIconProps = {};

export const FC = (props: SmallCustomIconProps) => {

  return (
    <div>SmallCustomIcon</div>
  );
};
