import React from 'react';
import { SvgIconProps } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../../../assets/Scenario/Close.svg';


export type IconType =
'CLOSE';

export type LargeCustomIconProps = {
  icon: IconType;
};

const __icon2iconType: Record<IconType, React.ReactElement> = {
  CLOSE: <CloseIcon />
};

export const FC = (props: LargeCustomIconProps & SvgIconProps) => {
  const Res = __icon2iconType[props.icon];
  return (
    <Res.type {...props} />
  );
};
