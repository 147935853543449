import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography
} from '@mui/material';
import React from 'react';



export type StyledDropDownProps = {
  validationErrorMessage?: string;
  label?: string;
  onChange: (e: SelectChangeEvent<unknown>) => void;
  options: {
    id: string;
    name: string;
    displayName: string;
  }[];
};

export const FC = (props: StyledDropDownProps & SelectProps) => {

  return (
    <FormControl
      fullWidth
      required = {props.required}
      error = {props.validationErrorMessage && props.validationErrorMessage.length > 1 ? true : false}
    >
      <FormLabel>
        <Typography
          variant = 'formLabel'
        >
          {props.label}
        </Typography>
      </FormLabel>
      <Select
        variant = 'standard'
        multiple = {props.multiple}
        value = {props.value ?? ''}
        onChange = {e => props.onChange(e)}
      >
        {props.options.map((option, i) => (
          <MenuItem
            key = {i}
            value = {option.name}
          >
            {option.displayName}
          </MenuItem>
        ))}
      </Select>
      {
        props.validationErrorMessage && props.validationErrorMessage.length > 1
          ? (
            <FormHelperText variant = 'standard'>
              {props.validationErrorMessage}
            </FormHelperText>
          )
          : (
            <></>
          )
      }
    </FormControl>
  );
};
