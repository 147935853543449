import React from 'react';
import { RouteObject } from 'react-router-dom';
import { BasicThemeActivity } from './BasicThemeActivity/BasicThemeActivity';
import { ThemeLayout } from './ThemeLayout';



export const root: RouteObject = {
  path: 'theme',
  element: <ThemeLayout />,
  children: [{
    path: '',
    element: <BasicThemeActivity />
  }]
  // element: <BasicThemeActivity />
};
