import { faker } from '@faker-js/faker';
import {
  Stack,
  Box,
  Typography,
  Button,
  ButtonBaseProps,
  ButtonPropsVariantOverrides,
  ButtonOwnProps
} from '@mui/material';

import React from 'react';
import { theme } from 'theme';



export type ButtonsProps = {};

export const FC = (props: ButtonsProps) => {
  const buttonOptions = theme.components?.MuiButton?.styleOverrides;
  let keys: (ButtonOwnProps['variant'])[] = [];
  if (buttonOptions) {
    keys = Object.keys(buttonOptions).map(k => k as ButtonOwnProps['variant']);
  } else {
    keys = [];
  }

  const elements = keys.map((option, i: number) => {
    console.log(option);
    return (
      <Stack
        key = {i}
        direction = 'column'
      >
        <Box
          borderBottom = '1px solid black'
        >
          <Button
            variant = {option}
          >
            {option}
          </Button>
        </Box>
      </Stack>
    );
  });
  return (
    <Box
      display = 'flex'
      flexDirection = 'row'
      columnGap = {2}
    >
      {elements}
    </Box>

  );
};
