import {
  County,
  EmailScenario_Input,
  State,
  useEmailScenarioMutation,
  useGetScenarioDataAsCsvLazyQuery,
  useGetScenarioDetailQuery
} from 'generated/graphql';
import React from 'react';

export interface IData {
  id: string;
  name: string;
  message: string;
  counties: Partial<County>[];
  state: Partial<State>;
  humanReadableId: string;
}

export function useQuery(scenarioId: string) {
  const tuple = useGetScenarioDetailQuery({
    variables: {
      scenarioId
    },
    skip: scenarioId === ''
  });

  return React.useMemo(
    () => {
      const data: IData = {
        id: tuple.data?.scenario?.id ?? '',
        name: tuple.data?.scenario?.name ?? '',
        message: tuple.data?.scenario?.message ?? '',
        counties: tuple.data?.scenario?.counties ?? [],
        state: tuple.data?.scenario?.state ?? {
          id: '',
          abbreviation: '',
          name: ''
        },
        humanReadableId: tuple.data?.scenario?.humanReadableID ?? ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useGetScenarioDataAsCsvQuery(scenarioId: string) {
  const [
    fnQuery,
    tuple
  ] = useGetScenarioDataAsCsvLazyQuery({

  });

  const fnWrappedQuery = React.useCallback(
    async () => {
      const query = await fnQuery({
        variables: {
          id: scenarioId
        }
      });
      if (query.data?.downLoadScenarioCsv?.data) {
        const a = document.createElement('a'); // Create <a>
        a.href = 'data:text/plain,' + query.data.downLoadScenarioCsv.data; // Image Base64 Goes here
        a.download = 'countyData.csv'; // File name Here
        a.click(); // Downloaded file
      }
    },
    [
      fnQuery,
      scenarioId
    ]
  );

  return React.useMemo(
    () => [
      fnWrappedQuery,
      {
        ...tuple,
        data: null
      }
    ] as const,
    [
      fnWrappedQuery,
      tuple
    ]
  );
}

