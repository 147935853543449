import { useGetAllScenariosQuery } from 'generated/graphql';
import { DateTime } from 'luxon';
import React from 'react';

export interface IData {
  id: string;
  name: string;
  description: string;
  dateCreated: string;
  humanReadableId: string;
  state: string;
  counties: string[];
}

export function useQuery() {
  const tuple = useGetAllScenariosQuery({
    variables: {
      options: {

      }
    }
  });

  return React.useMemo(
    () => {
      const data: IData[] = tuple.data?.scenarios.nodes.map(node => ({
        id: node.id ?? '',
        name: node.name ?? '',
        description: node.message ?? '',
        dateCreated: DateTime.fromISO(node.dateCreated).toFormat('LLL dd, yyyy') ?? '',
        humanReadableId: node.humanReadableID ?? '',
        state: node.state.abbreviation ?? '',
        counties: node.counties.map((rec => rec.name))
      })) ?? [];

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
