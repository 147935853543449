import {
  Box,
  Stack,
  Typography
} from '@mui/material';
import { faker } from '@faker-js/faker';
import { Variant } from '@mui/material/styles/createTypography';
import React from 'react';
import { theme } from 'theme';



export type FontProps = {};

export const FC = (props: FontProps) => {

  return (
    <>
      {__generateTypographyElements()}
    </>
  );
};

const __generateTypographyElements = () => {
  const typographyOptions = Object.keys(theme.typography);

  const typographyOptionsVar = typographyOptions.map(option => {
    return option as Variant;
  });



  const elements = typographyOptionsVar.map(option => {
    return (
      <Stack
        key = {option}
        direction = 'column'
      >
        <Box
          borderBottom = '1px solid black'
        >
          <Typography
            variant = {option}
          >
            {option}
          </Typography>
        </Box>
        <Typography
          variant = {option}
        >
          {faker.word.words(10)}
        </Typography>
      </Stack>
    );
  });
  return (
    <Box
      display = 'flex'
      flexDirection = 'column'
      rowGap = {2}
    >
      {elements}
    </Box>
  );
};
