import {
  Stack,
  Typography,
  Box
} from '@mui/material';
import { appFormUtil } from 'modules/common';
import { InferReturn } from 'modules/common/layer00/appFormUtil';
import { StyledInputTextField } from 'modules/components/layer01';
import {
  SelectStateField,
  SelectCountiesField
} from 'modules/components/layer02';
import React, { ReactNode } from 'react';



export type BaseFormLayoutProps<T extends object> = {
  sectionTitle: string;
  sectionSubtitle?: string;
  pageIndex?: string;
  summary?: boolean;
  children: ReactNode | ReactNode[] | undefined;
};

export const FC = <T extends object>(props: BaseFormLayoutProps<T>) => {

  return (

    <Box>

      <Stack
        direction = 'row'
        justifyContent = 'space-between'
      >

        <Typography
          variant = 'modalTitle'
        >
          {props.sectionTitle ?? ''}
        </Typography>
        <Box>
          <Typography
            variant = 'modalStepCountActive'
          >
            {`${props.pageIndex}`} /
          </Typography>
          <Typography variant = 'modalStepCountTotal'>3</Typography>
        </Box>
      </Stack>

      {
        props.sectionSubtitle && (
          <Typography variant = 'modalSubTitle'>{props.sectionSubtitle}</Typography>
        )
      }


      <Stack
        marginTop = {props.summary ? '1rem' : '4rem'}
        direction = 'column'
      >
        {props.children}
      </Stack>
    </Box>

  );
};
