import React from 'react';

import {
  Add,
  Close
} from '@mui/icons-material';
import {
  Stack,
  Typography
} from '@mui/material';
import { DateTime } from 'luxon';
import { SummarySectionLayout } from 'modules';
import * as ds from './dataService';



export type ScenarioDetailSectionProps<T extends object> = {
  id: string;
};

interface IField {
  label: string;
  value: string;
}
export const FC = <T extends object> (props: ScenarioDetailSectionProps<T>) => {
  const { data } = ds.useQuery(props.id);


  const fields: IField[] = [
    {
      label: 'Scenario ID',
      value: data.humanReadableId
    },
    {
      label: 'Date',
      value: data.createdAt
    },
    {
      label: 'State',
      value: data.state.name ?? ''
    },
    {
      label: 'Scenario Name',
      value: data.name
    },
    {
      label: 'Selected Counties',
      value: data.counties.map(node => node.name).join(', ')

    }
  ];
  return (
    <SummarySectionLayout.FC
      sectionName = 'Scenario Details'
    >
      {fields.map(((field, i) => (
        <Stack
          key = {i}
          direction = 'row'
          alignItems = 'center'
          paddingBottom = '.5rem'
          columnGap = '.25rem'
        >
          <Typography
            variant = 'textFieldRegular'
          >
            {field.label}:
          </Typography>
          <Typography
            variant = 'textFieldBold'
          >
            {field.value}
          </Typography>
        </Stack>
      )))}
    </SummarySectionLayout.FC>
  );
};
