import {
  appFormUtil,
  useMobileView
} from 'modules/common';
import {
  SelectCountiesField,
  SelectCountiesMap,
  SelectStateField
} from 'modules';
import { BaseFormLayout } from 'modules';
import React from 'react';
import { useReactiveVar } from '@apollo/client';



export type ScenarioCountiesFormSelectionProps<T extends object> = {
  form: appFormUtil.InferReturn<T>;
  fields: (keyof appFormUtil.InferReturn<T>['formState'])[];
  pageIndex: keyof appFormUtil.InferReturn<T>['formState'];
  stateId?: string;
};

export const FC = <T extends object> (props: ScenarioCountiesFormSelectionProps<T>) => {
  const isMobile = useMobileView.useMobileView();
  const pageNumber = props.form.formState[props.pageIndex];

  return (
    <BaseFormLayout.FC
      sectionTitle = 'Select Counties'
      sectionSubtitle = 'Please select your state from the drop down menu, once you have selected a state a list of your counties will appear for your selection. Make a selection of one or multiple counties .'
      pageIndex = {`${pageNumber}`}
    >
      {props.fields.map((field, i) => {
        switch (field) {
          case 'counties':
            if (isMobile) {
              return (
                <SelectCountiesField.FC
                  key = {i}
                  fieldName = {field}
                  form = {props.form}
                  stateId = {props.stateId ?? ''}
                />
              );

            } else {
              return (

                <SelectCountiesMap.FC
                  key = {i}
                  fieldName = {field}
                  form = {props.form}
                  stateId = {props.stateId ?? ''}
                />
              );
            }


          case 'state':
            return (
              <SelectStateField.FC
                key = {i}
                fieldName = {field}
                form = {props.form}
              />
            );
          default:
            return <></>;
        }
      })}
    </BaseFormLayout.FC>

  );
};
