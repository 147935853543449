import {
  Box,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';


import { theme } from 'theme';

export type ColorsProps = {};

export const FC = (props: ColorsProps) => {

  return (
    <Box>
      <Typography variant = 'h4'>Colors</Typography>
      <Box
        display = 'flex'
        flexDirection = 'column'
        rowGap = {4}
      >
        {__generateColorElements('primary')}
        {__generateColorElements('gray')}
        {__generateColorElements('secondary')}
        {__generateColorElements('info')}
        {__generateColorElements('error')}
        {__generateColorElements('warning')}
        {__generateColorElements('success')}

      </Box>
    </Box>
  );
};


const __generateColorElements = (color: keyof typeof theme['palette']) => {
  const colorOptions = Object.keys(theme.palette[color]);

  const elements = colorOptions.map(option => {
    if (theme.palette[color][option as keyof typeof theme['palette'][typeof color]]) {
      return (
        <Stack
          flex = ''
          key = {option}
          direction = 'column'
        >
          <Box
            width = '50px'
            height = '50px'
            bgcolor = {
              theme => theme.palette[color][option as keyof typeof theme['palette'][typeof color]]
            }
          />
          <Typography variant = 'h4'>{option}</Typography>
        </Stack>
      );
    } else return (
      <Stack flex = '' key = {option} direction = 'column'>
        <Box width = '50px' height = '50px' bgcolor = {theme => theme.palette[color][option as keyof typeof theme['palette'][typeof color]]} />
        <Typography variant = 'h4'></Typography>
      </Stack>
    );
  });
  return (
    <Box >
      <Typography variant = 'h3'>{color.toUpperCase()}</Typography>
      <Box display = 'flex' flexWrap = 'wrap' columnGap = {2}>
        {elements}
      </Box>
    </Box>
  );
};
