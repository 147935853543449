import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';



export type BaseDialogProps = {
  isVisible: boolean;
  handleClose: () => void;
};

export const FC = (props: BaseDialogProps) => {

  return (
    <Dialog
      open = {props.isVisible}
      onClose = {props.handleClose}
    >
      <DialogTitle>
        <Stack
          direction = 'row'
          justifyContent = 'space-between'
        >
          <Typography variant = 'modalTitle'>Modal Title</Typography>
          <IconButton>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction = 'column'
        >
          <FormControl
            fullWidth
          >
            <FormLabel>
              <Typography variant = 'formLabel' />
            </FormLabel>
            <Input
              placeholder = 'input field'
            />
          </FormControl>
          <FormControl
            fullWidth
          >
            <FormLabel>
              <Typography variant = 'formLabel' />
            </FormLabel>
            <Input
              placeholder = 'input field'
            />
          </FormControl>
          <FormControl
            fullWidth
          >
            <FormLabel>
              <Typography variant = 'formLabel' />
            </FormLabel>
            <Input
              placeholder = 'input field'
            />
          </FormControl>

        </Stack>

        <Stack
          marginTop = '1rem'
          borderTop = '1px solid gray'
          paddingTop = '1rem'
          direction = 'row'
          columnGap = '10rem'
        >
          <Button
            variant = 'outlined'
            color = 'primary'
          >
            Close
          </Button>
          <Button
            variant = 'contained'
            color = 'primary'
          >
            Save
          </Button>
        </Stack>


      </DialogContent>
    </Dialog>
  );
};
