import {
  Add,
  Close
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton
} from '@mui/material';
import React, {
  ReactNode,
  useState
} from 'react';


export type PracticeRate = {
  year: string;
  values: string[];
};

export type SelectedCountyRatesCardProps = {
  countyName: string;
  children: ReactNode | ReactNode[] | undefined;
};

export const FC = (props: SelectedCountyRatesCardProps) => {
  const [
    isExpanded,
    setIsExpanded
  ] = useState(false);

  return (
    <Card>
      <CardHeader
        title = {props.countyName}
        action = {(
          <IconButton

            onClick = {() => setIsExpanded(prev => !prev)}
          >

            {isExpanded ? <Close /> : <Add />}
          </IconButton>
        )}
      />
      <Collapse
        in = {isExpanded}
        unmountOnExit
      >
        <CardContent>

          {props.children}
        </CardContent>
      </Collapse>
    </Card>
  );
};
