
import {
  CssBaseline,
  ThemeProvider
} from '@mui/material';
import { router } from 'activities';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { theme } from 'theme';



function App() {


  return (
    <>
      <ThemeProvider theme = {theme}>
        <CssBaseline />
        <RouterProvider router = {router} />
      </ThemeProvider>
    </>
  );
}

export default App;
