import { faker } from '@faker-js/faker';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { UserTableRow } from 'modules/components/layer23';
import React from 'react';

export interface IData extends UserTableRow.UserTableRowProps { }

export function useQuery() {
  // const tuple = useGet({
  //   variables: {
  //   }
  // });

  return React.useMemo(
    () => {
      const data: IData[] = _.times(10, () => ({
        id: faker.string.uuid(),
        fullName: faker.person.fullName(),
        accountCreatedDate: DateTime.fromJSDate(faker.date.recent()).toFormat('LLL dd, yyyy'),
        email: faker.internet.email(),
        phone: faker.phone.number(),
        profileImg: faker.image.avatar()
      }));

      return {
        // ...tuple,
        data
      };
    },
    // [tuple]
    []
  );
}
