import { setContext } from '@apollo/client/link/context';
import {
  authUtil,
  configUtil
} from '../../layer00';

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  makeVar
} from '@apollo/client';
import { AuthenticationStatus } from 'generated/graphql';

const { envName } = configUtil.getConfig();

const httpAuthLink = setContext(async ({ context }) => {
  const accessToken = await authUtil.getAccessToken(); // Always returns a good access token if logged in.

  if (accessToken) {
    return {
      headers: {
        ...context?.headers,
        authorization: `Bearer ${accessToken}`
      }
    };
  }

  return {
    headers: {
      ...context?.headers
    }
  };
});



const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL_URL
});



const __makeClient = () => {
  if (envName !== 'dev' && envName !== 'prod') {
    console.log('Creating New apollo client');
  }
  // console.log("Creating New apollo client");
  return new ApolloClient({
    link: from([
      httpAuthLink,
      httpLink
    ]),
    connectToDevTools: true,
    cache: __makeCache()
  });
};



function __makeCache() {
  return new InMemoryCache({
    typePolicies: {

      Query: {

        fields: {
          loggedInUser: {
            read: (existing, { readField, isReference, cache, toReference }) => {

            }
          },
          authQuery: {
            read: (existing, { readField, args, toReference, cache, storage }) => {
              const accessToken = readField('accessToken');
              const loggedInUserId = readField('loggedInUserId');
              const authStatus = readField('authStatus');
              const authError = readField('authError');
              // const isSuperAdmin = readField('isSuperAdmin');

              if (!accessToken) {
                // console.log('NO access token');
              } else {
                // console.log(accessToken);
              }

              const newItem = {
                authStatus, accessToken, loggedInUserId, authError
              };
              return newItem;

            }
          },
          isSuperAdmin: {
            read: (existing, { cache }) => {
              const isSuperAdmin = isLoggedInUserSuperAdmin();
              return isSuperAdmin;

            }
          },

          loggedInUserId: {
            read: existing => {
              return 'userId';
            }
          },

          authStatus: {
            read: (): AuthenticationStatus => {
              const loggedIn = isLoggedInVar();

              if (!loggedIn) {
                return 'signedOut';
              }

              // What actually needs to be returned here.
              // signedOut
              // registrationRequired
              // confirmSignUp
              // signedIn
              // confirmationCodeRequired

              // const appLoggedIn = isLoggedInVar();
              // if (appLoggedIn !== loggedIn) {
              //   try {
              //     isLoggedInVar(loggedIn);
              //   } catch {

              //   }
              // }

              return 'signedIn';
              // adding the reactive the query DOES make this readQuery rerun whenever we chack this.... therefore we could essentially use this concept to check the auth token exists and if valid.
              // return 'hello from auth';
            }
          },
          authError: {
            read: () => {
              return null;
            }
          }

        }
      }

    }
  });
}



export const isLoggedInVar = makeVar(authUtil.evaluateInitialIsLoggedIn());
export const isLoggedInUserSuperAdmin = makeVar<boolean>(false);

export const client = __makeClient();



authUtil.obsChangeAuthStatus.subscribe(value => {
  if (!value) {
    client.cache.evict({
    });
    client.cache.reset();
  }
  isLoggedInVar(value);
});



