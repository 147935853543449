import {
  Box,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { useMobileView } from 'modules/common';
import { CustomIcons } from 'modules/components/layer00';
import React from 'react';



export type ScenarioBuilderHeader = {};

export const FC = (props: ScenarioBuilderHeader) => {
  const theme = useTheme();
  const isMobile = useMobileView.useMobileView();
  return (
    <Box
      display = 'flex'
      flexDirection = 'row'
      justifyContent = 'space-between'
      alignItems = 'center'
      bgcolor = {theme.palette.primary.light05}
      padding = {isMobile ? '1rem 1rem' : '1rem 5rem'}
      borderBottom = {`1px solid ${theme.palette.primary.light10}`}
    >
      <Typography variant = 'activityHeader'>Create a new Scenario</Typography>
      <IconButton
        LinkComponent = 'a'
        href = 'https://www.crpinfo.com'
      >

        <CustomIcons.large.FC icon = 'CLOSE' stroke = '#313435' />
      </IconButton>
    </Box>
  );
};

