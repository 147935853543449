import { makeVar } from '@apollo/client';
import React, {
  useEffect,
  useState
} from 'react';



export type useSizeProps = {};

export const useMobileView = () => {


  const [
    windowWidth,
    setWindowWith
  ] = useState(window.innerWidth);

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener('resize', windowSizeHandler);

    return () => {
      window.removeEventListener('resize', windowSizeHandler);
    };
  });

  return windowWidth <= 500 ? true : false;
};
