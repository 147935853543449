import React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';

import { CountyRatesTableRow } from 'modules/components/layer23';
import { CountyProgram } from 'generated/graphql';



export type CountyRentalRatesTableProps = {
  id: string;
  countyName: string;
  state: string;
  countyPrograms: CountyProgram[];
};

export const FC = (props: CountyRentalRatesTableProps) => {
  const theme = useTheme();

  const headers = [
    'Year',
    ...props.countyPrograms.map(node => node.practice.code)
  ] ?? [];


  const years = [...new Set(props.countyPrograms.flatMap(node => {
    const programData = node.data?.flatMap(program => program.year) ?? [];
    return programData;
  }))];



  const rows = years.map(yr => __generateRow(yr, props.countyPrograms));

  return (
    <TableContainer
      sx = {{

        padding: '.75rem',
        border: `1px solid ${theme.palette.gray.light20}`,
        borderRadius: '.25rem'
      }}
    >
      {/* <Box > */}
      <Typography
        variant = 'tableTitle'
      >
        Rental rates ({props.countyName}, {props.state} | General CRP)
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell
                key = {`header-${i}`}
                variant = 'head'
              >
                {header}
              </TableCell>
            ))}

          </TableRow>
        </TableHead>
        <TableBody>

          {rows.map((row, i) => (
            <CountyRatesTableRow.FC
              key = {`${row.year}-row-${i}`}
              values = {[
                `${row.year}`,
                ...row.values.map(r => r.value)
              ]}
            />
          ))}
        </TableBody>
      </Table>
      {/* </Box> */}
    </TableContainer>
  );
};



const __generateRow = (year: number, countyPrograms: CountyProgram[]): {
  year: number;
  values: {
    countyCode: string;
    value: string;
  }[];
} => {

  const data = countyPrograms.flatMap((node, i) => {
    const countyCode = countyPrograms[i].practice.code;
    return node.data.filter(r => r.year === year).map(n => ({
      countyCode,
      value: `${n.rentalRate}`
    }));
  }) ?? [];

  return {
    year,
    values: data
  };
};
