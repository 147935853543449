import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Typography
} from '@mui/material';
import { appFormUtil } from 'modules/common';
import { BaseModalLayout } from 'modules/components/layer14';
import React, { useState } from 'react';

import * as ds from './dataService';
import { StyledInputTextField } from 'modules/components/layer01';
import { theme } from 'theme';



export type ShareScenarioModalProps = {
  id: string;
  isVisible: boolean;
  handleClose: () => void;
};

export const FC = (props: ShareScenarioModalProps) => {
  const [
    isSent,
    setIsSent
  ] = useState(false);

  const [fnSendEmail] = ds.useEmailScenario();
  const { initialState } = ds.useQuery(props.id);
  const form = appFormUtil.useFormState({
    initialState,
    key2FieldConfig: {
      message: {
        label: 'Your Message to Recipient',
        type: 'text',
        required: false
      },
      preferredEmail: {
        label: 'Recipient\'s email',
        type: 'email',
        required: true
      },
      name: {
        label: 'Your Name',
        type: 'text',
        required: true
      },
      scenarioId: {
        type: 'text'
      }
    }
  });

  const fields: (keyof typeof form.formState)[] = [
    'name',
    'preferredEmail',
    'message'
  ];

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(form.formState.scenarioId ?? '');
  };

  return (
    <BaseModalLayout.FC
      title = 'Share Scenario'
      subtitle = {isSent ? 'We have sent an email with your scenario. If you have any issues please contact us and provide your scenario ID.' : 'Please enter your receipients information. '}
      isVisible = {props.isVisible}
      handleClose = {props.handleClose}
      handleSave = {() => {}}
    >
      <Box
        component = 'form'
      >
        <Stack
          direction = 'column'
        >
          {
            !isSent
              ? (fields.map((field, i) => (
                <StyledInputTextField.FC
                  key = {i}
                  {...form.genFieldProps(field)}
                />
              ))
              )
              : (
                <Stack
                  direction = 'row'
                  columnGap = '.5rem'
                  flexWrap = 'wrap'
                >
                  <Box
                    flex = '1 1 0'
                  >

                    <Typography variant = 'modalTitle'>
                      Scenario ID:
                    </Typography>
                    <Typography
                      variant = 'modalTitle'
                      color = {theme.palette.primary.main}
                    >
                      {form.formState.scenarioId}
                    </Typography>
                  </Box>
                  <Button
                    variant = 'outlined'
                    color = 'primary'
                    onClick = {e => handleCopy(e)}
                  >
                    Copy to clipboard
                  </Button>
                </Stack>

              )
          }

        </Stack>
        <Box
          display = 'flex'
          flexDirection = 'row'
          paddingTop = '1rem'
          borderTop = '1px solid gray'
          marginTop = '1rem'
          columnGap = '10rem'
          justifyContent = 'space-between'
        >
          <Button
            variant = 'outlined'
            color = 'primary'
            onClick = {props.handleClose}
          >
            Cancel
          </Button>

          <Button
            onClick = {
              async () => {
                if (!isSent) {
                  await fnSendEmail({
                    id: props.id,
                    message: form.formState.message,
                    recepientEmail: form.formState.preferredEmail
                  });
                  setIsSent(() => true);
                } else {
                  props.handleClose();
                }
              }
            }
            variant = 'contained'
            color = 'primary'
          >
            {isSent ? 'Ok I understand' : 'Share'}
          </Button>

        </Box>
      </Box>
    </BaseModalLayout.FC>
  );

};
