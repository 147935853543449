import {
  Archive,
  Delete
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';



export type FormCompentsProps = {};

export const FC = (props: FormCompentsProps) => {

  return (
    <Box
      paddingTop = '4rem'
      paddingBottom = '4rem'
      width = '50vw'
    >
      <Box
        component = 'form'
      >
        <Stack>

          <FormControl
            required = {true}
          >
            <FormControlLabel label = {<Typography variant = 'formLabel'> Label</Typography>}control = {<Checkbox />} />


          </FormControl>
          <FormControl
            required = {true}
          >
            <FormLabel>

              <Typography
                variant = 'formLabel'
              >
                Name
              </Typography>
            </FormLabel>
            <Input
              required = {true}
              placeholder = 'name'
              value = 'Bill Smith'
            />
            <FormHelperText
              variant = 'standard'
            >
              This is any helper text
            </FormHelperText>

          </FormControl>
          <FormControl
            required = {true}
          >
            <FormLabel>

              <Typography
                variant = 'formLabel'
              >
                Name
              </Typography>
            </FormLabel>
            <Input
              required = {true}
              endAdornment = {<Delete />}
              placeholder = 'name'
              value = 'Bill Smith'
            />

          </FormControl>
          <FormControl
            required = {true}
          >
            <FormLabel>

              <Typography
                variant = 'formLabel'
              >
                Name
              </Typography>
            </FormLabel>
            <Select
              required = {true}
              placeholder = 'name'
              value = '1'
            >
              <MenuItem
                value = '1'
              >
                Georgia
              </MenuItem>

            </Select>

          </FormControl>
        </Stack>
      </Box>
    </Box>
  );
};
