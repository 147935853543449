import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  client,
  configUtil
} from 'modules';
import { ApolloProvider } from '@apollo/client';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const { envName } = configUtil.getConfig();

if (envName !== 'devSumner' && envName !== 'devLocal' && envName !== 'prod') {
  // markerUtil.init();
}

root.render(
  <React.StrictMode>
    <ApolloProvider client = {client}>

      <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
