import React from 'react';
import { faker } from '@faker-js/faker';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import _ from 'lodash';



export type TableProps = {};

export const FC = (props: TableProps) => {
  const headers = [
    'Year',
    'CP34',
    'CP36',
    'Practice A',
    'Practice B',
    'Practice C',
    'Practice D'
  ];
  const data = _.times(10, i => {
    const rowData = [
      `${2024 - i}`,
      faker.number.int({
        min: 7000, max: 40000
      }),
      faker.number.int({
        min: 7000, max: 40000
      }),
      faker.number.int({
        min: 7000, max: 40000
      }),
      faker.number.int({
        min: 7000, max: 40000
      }),
      faker.number.int({
        min: 7000, max: 40000
      }),
      faker.number.int({
        min: 7000, max: 40000
      })

    ];
    return rowData;
  });
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell
                variant = 'head'
                key = {`headerCell-${i}`}
              >
                {header}
              </TableCell>

            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow
              key = {row[0]}
            >
              {row.map((r, i) => (
                <TableCell
                  key = {`${r}-${i}`}
                  variant = {i === 0 ? 'bold' : 'body'}
                >
                  {r}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
