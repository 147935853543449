
import { Amplify } from 'aws-amplify';
import * as auth from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import awsExports from 'aws-exports';
import { BehaviorSubject } from 'rxjs';



Amplify.configure(awsExports);



export function evaluateInitialIsLoggedIn() {
  // Type casting for Amplify because the types don't seem to match what amplify has
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const exports = awsExports as any;
  const lastAuthUser = localStorage.getItem(`CognitoIdentityServiceProvider.${exports.aws_user_pools_web_client_id ?? ''}.LastAuthUser`);
  return lastAuthUser !== null;
}

export const obsChangeAuthStatus = new BehaviorSubject<boolean>(evaluateInitialIsLoggedIn());



Hub.listen('auth', async ({ payload }) => {

  if (payload.event === 'signedIn') {
    obsChangeAuthStatus.next(true);

  } else if (payload.event === 'signedOut') {
    // Sign out
    obsChangeAuthStatus.next(false);

  } else if (payload.event === 'tokenRefresh') {

  }
});



export async function signIn(email: string, password: string) {
  return auth.signIn({
    username: email.trim(),
    password: password.trim()
  });
}



export async function signUp(email: string, password: string) {
  const { nextStep } = await auth.signUp({
    username: email.trim(),
    password: password.trim()
  });

  return nextStep.signUpStep;
}



export async function confirmSignUp({ email, confirmationCode }: {
  email: string;
  password: string;
  confirmationCode: string;
}) {
  return auth.confirmSignUp({
    username: email.trim(),
    confirmationCode: confirmationCode.trim()
  });
}



export async function signOut() {
  await auth.signOut({
    global: true
  });
}



export async function setUpTOTP() {
  return auth.setUpTOTP();
}



export async function resetPassword(email: string) {
  await auth.resetPassword({
    username: email
  });
}



export async function confirmResetPassword({ email, password, confirmationCode }: {
  email: string;
  password: string;
  confirmationCode: string;
}) {
  await auth.confirmResetPassword({
    username: email,
    newPassword: password,
    confirmationCode
  });
}



export const getAccessToken = async () => {
  try {
    const session = await auth.fetchAuthSession();
    if (session.tokens) {
      // write to the cache the values
      console.log(session.tokens.accessToken.toString());
      return session.tokens.accessToken.toString();
    }

    return null;

  } catch (err) {
    return null;
  }
};



export async function verify(code: string) {
  return auth.verifyTOTPSetup({
    code
  });
}



export async function confirmSignIn(code: string) {
  return auth.confirmSignIn({
    challengeResponse: code
  });
}
