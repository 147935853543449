import {
  ScenarioDetailsSection,
  ScenarioSummaryDetailSection
} from 'modules';
import React from 'react';
import { useParams } from 'react-router-dom';



export type ScenarioDetailActivityProps = {};

export const ScenarioDetailActivity = (props: ScenarioDetailActivityProps) => {
  const params = useParams();

  return (
    <ScenarioDetailsSection.FC
      id = {params.scenarioId ?? ''}
    />
  );
};
