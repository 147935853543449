import { appFormUtil } from 'modules/common';
import { StyledDropDown } from 'modules/components/layer01';
import React from 'react';
import * as ds from './dataService';


export type SelectCountiesFieldProps<T extends object> = appFormUtil.SelectFieldProps<T>;

export const FC = <T extends object> (props: SelectCountiesFieldProps<T> & { stateId?: string }) => {
  const { data } = ds.useQuery(props.stateId ?? '');



  return (
    <StyledDropDown.FC
      {...props.form.genFieldProps(props.fieldName)}
      multiple
      options = {data}
    />
  );
};
