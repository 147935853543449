import { faker } from '@faker-js/faker';
import {
  County,
  State,
  useGetScenarioDetailQuery
} from 'generated/graphql';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';

export interface IData {
  id: string;
  name: string;
  message: string;
  counties: Partial<County>[];
  state: Partial<State>;
  humanReadableId: string;
  createdAt: string;
}

export function useQuery(scenarioId: string) {
  const tuple = useGetScenarioDetailQuery({
    variables: {
      scenarioId
    },
    skip: scenarioId === ''
  });

  return React.useMemo(
    () => {
      const data: IData = {
        id: tuple.data?.scenario?.id ?? '',
        name: tuple.data?.scenario?.name ?? '',
        createdAt: DateTime.fromISO(tuple.data?.scenario?.dateCreated ?? '').toFormat('LLL dd yyyy'),
        message: tuple.data?.scenario?.message ?? '',
        counties: tuple.data?.scenario?.counties ?? [],
        state: tuple.data?.scenario?.state ?? {
          id: '',
          abbreviation: '',
          name: ''
        },
        humanReadableId: tuple.data?.scenario?.humanReadableID ?? ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
