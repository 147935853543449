import React from 'react';

import {
  createBrowserRouter,
  RouteObject
} from 'react-router-dom';
import * as theme from './theme';
import * as scenario from './scenario';
import * as admin from './admin';
import { RootLayout } from './RootLayout';



export const routes: RouteObject[] = [{
  path: '',
  element: (<RootLayout />),
  errorElement: <div>Error!</div>,
  children: [
    theme.root,
    admin.root,
    scenario.root
  ]
}];


export const router = createBrowserRouter(routes);
