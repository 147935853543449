import { SelectChangeEvent } from '@mui/material';
import _ from 'lodash';

import {
  useCallback,
  useRef,
  useReducer,
  useMemo,
  HTMLInputTypeAttribute,
  useEffect
} from 'react';

export type SelectFieldProps<T extends object> = {
  form: InferReturn<T>;
  fieldName: keyof InferReturn<T>['formState'];
};


export type IUpdateAction<TState extends object, TKey extends keyof TState = keyof TState> = {
  type: 'UPDATE';
  payload: {
    property: TKey;
    value: TState[TKey];
  };
};

export type IResetAction = {
  type: 'RESET';
};

export type ITrimAction = {
  type: 'TRIM';
};

export type IBatchUpdateAction<TState extends object> = {
  type: 'BATCH_UPDATE';
  payload: TState;
};

export type IAction<TState extends object> = (
  IUpdateAction<TState> |
  IResetAction |
  ITrimAction |
  IBatchUpdateAction<TState>
);



export type IFieldConfig<TState, TKeyValue> = {
  label?: string;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  fnValidate?: (value: TKeyValue, state: TState) => string;
};

export function useFormState<TState extends object, TKey extends keyof TState = keyof TState>({
  initialState, key2FieldConfig = {
  }
}: {
  initialState: TState;
  key2FieldConfig?: Partial<Record<TKey, IFieldConfig<TState, TState[TKey]>>>;
}) {
  const fnReducer = useCallback(
    (currentState: TState, action: IAction<TState>): TState => {
      switch (action.type) {
        // Currently only handles strings and numbers. Need to think through other value types.
        case 'UPDATE':
          if (typeof action.payload.value === typeof currentState[action.payload.property]) {
            return {
              ...currentState,
              [action.payload.property]: action.payload.value
            };
          }
          break;

        case 'TRIM':
          return recursivelyTrimFormStateValues(currentState);

        case 'BATCH_UPDATE':
          return {
            ...action.payload
          };

        case 'RESET':
          return {
            ...initialState
          };
      }
      return currentState;
    },
    [initialState]
  );

  const refInitialState = useRef(initialState);
  const [
    formState,
    dispatch
  ] = useReducer(fnReducer, refInitialState.current);



  useEffect(
    () => {
      if (!_.isEqual(refInitialState.current, initialState)) {
        dispatch({
          type: 'BATCH_UPDATE',
          payload: initialState
        });
        refInitialState.current = initialState;
      }
    },
    [initialState]
  );


  const genFieldProps = useCallback(
    <TThisKey extends keyof typeof key2FieldConfig, TValue extends TState[TThisKey]>(fieldKey: TThisKey) => {
      const value = formState[fieldKey];
      const defaultProps = key2FieldConfig[fieldKey] ?? {
      };
      let errorMessage = defaultProps.fnValidate?.(value, formState);

      if (!errorMessage && (defaultProps.required && !value)) {
        errorMessage = 'Required.';
      }
      const errorObj = errorMessage
        ? {
          error: !!errorMessage,
          helperText: errorMessage
        }
        : {
        };

      return {
        ...defaultProps,
        ...errorObj,
        value: formState[fieldKey],
        onBlur: () => dispatch({
          type: 'TRIM'
        }),
        onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent | SelectChangeEvent<unknown>) => {
          if (formState[fieldKey] !== event.target.value) {
            dispatch({
              type: 'UPDATE',
              payload: {
                property: fieldKey,
                // Hack to get around type issue since we know above.
                value: event.target.value as TValue
              }
            });
          }
        }
      };
    },
    [
      key2FieldConfig,
      formState
    ]
  );



  const isValid = useCallback(
    () => {
      const formKeys = _.keys(key2FieldConfig) as Array<keyof typeof key2FieldConfig>;
      return _.isEmpty(formKeys) || !formKeys.some(formKey => {
        const defaultProps = key2FieldConfig[formKey];

        const value = formState[formKey];
        const reqd = (defaultProps?.required && !value);
        const valid = (defaultProps?.fnValidate && defaultProps.fnValidate(value, formState).length !== 0);
        return (
          reqd || valid
        );
      });
    },
    [
      formState,
      key2FieldConfig
    ]
  );



  return useMemo(
    () => ({
      formState,
      dispatch,
      genFieldProps,
      isValid
    }),
    [
      genFieldProps,
      isValid,
      formState
    ]
  );
}



export interface IFullFieldConfig<
  TState,
  TKey extends keyof TState = keyof TState
> extends IFieldConfig<TState, TState[TKey]> {
  fieldName: TKey;
  validationErrorMessage: string;
}

export function useBaseFormState<
  TState extends object,
  TKey extends keyof TState = keyof TState
>({
  formState, key2FieldConfig = {
  }
}: {
  formState: TState;
  key2FieldConfig?: Partial<Record<TKey, IFieldConfig<TState, TState[TKey]>>>;
}) {
  const val = useMemo((): Record<TKey, IFullFieldConfig<TState>> => _.mapValues(
    formState,
    (value, key): IFullFieldConfig<TState> => {
      const k = key as TKey;
      const defaultProps = key2FieldConfig[k] ?? {
      };
      const errorMessage = defaultProps.fnValidate?.(value as TState[TKey], formState) ?? '';
      return {
        ...(defaultProps as any),
        name: k,
        validationErrorMessage: errorMessage
      };
    }
  ), [
    formState,
    key2FieldConfig
  ]);

  const genFieldProps = useCallback(
    (fieldKey: keyof typeof key2FieldConfig) => {
      const explodedProps = val[fieldKey];
      const errorObj = explodedProps.validationErrorMessage
        ? {
          error: true,
          helperText: explodedProps.validationErrorMessage
        }
        : {
        };
      return {
        ...explodedProps,
        ...errorObj,
        value: formState[fieldKey]
      };
    },
    [
      formState,
      val
    ]
  );



  return useMemo(
    () => ({
      formState,
      key2FieldConfig: val,
      genFieldProps
    }),
    [
      formState,
      val,
      genFieldProps
    ]
  );
}



export function recursivelyTrimFormStateValues<T>(formState: T): T {
  if (_.isObject(formState)) {
    return _.mapValues(formState, value => {
      if (_.isString(value)) {
        return _.trim(value);
      }

      if (_.isArray(value)) {
        return _.map(value, v => recursivelyTrimFormStateValues(v));
      }

      if (_.isDate(value)) {
        return value;
      }

      if (_.isObject(value)) {
        return recursivelyTrimFormStateValues(value);
      }

      return value;
    }) as T;
  }

  // Generally shouldn't happen but this function can recurse and be passed a string.
  if (_.isString(formState)) {
    return _.trim(formState) as T;
  }

  return formState;
}



function useGenFieldProps<
  TState extends object,
  TKey extends keyof TState = keyof TState
>(
  formState: TState,
  key2FieldConfig: Partial<Record<TKey, IFieldConfig<TState, TState[TKey]>>>,
  dispatch: React.Dispatch<IAction<TState>>,
  trimOnBlur?: boolean
) {
  return useCallback(
    (fieldKey: keyof typeof key2FieldConfig) => {
      const defaultProps = key2FieldConfig[fieldKey] ?? {
      };
      const errorMessage = defaultProps.fnValidate?.(formState[fieldKey], formState) ?? '';
      const errorObj = errorMessage
        ? {
          error: !!errorMessage,
          helperText: errorMessage
        }
        : {
        };

      return {
        ...defaultProps,
        ...errorObj,
        value: formState[fieldKey],
        onBlur: trimOnBlur
          ? () => dispatch({
            type: 'TRIM'
          })
          : undefined,
        onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (_.isString(formState[fieldKey]) && _.isString(event.target.value)) {
            dispatch({
              type: 'UPDATE',
              payload: {
                property: fieldKey,
                // Hack to get around type issue since we know above.
                value: event.target.value as never
              }
            });
          }
        }
      };
    },
    [
      key2FieldConfig,
      formState,
      trimOnBlur,
      dispatch
    ]
  );
}

export type InferReturn<T extends object> = ReturnType<typeof useFormState<T>>;
