import { createTheme } from '@mui/material';
import { customPalette } from './layer00';
import { CustomTypography } from './layer01';

export const theme = createTheme({
  typography: {
    ...CustomTypography.typography
  },
  palette: {
    ...customPalette.palette
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            border: `1px solid ${theme.palette.gray.light20}`,
            borderRadius: '.25rem',
            padding: '.75rem'
          };
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        title: ({ theme }) => {
          return {
            ...theme.typography.cardHeader
          };
        },
        action: ({ theme }) => {
          return {
            stroke: theme.palette.primary.main
          };
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            borderRadius: '.75rem',
            padding: '.5rem 1.5rem 2.5rem 1.5rem'
          };
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ }) => {
          return {
            justifyContent: 'flex-end',
            paddingRight: 0,
            paddingLeft: 0
          };
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => {
          return {
            ...theme.typography.cellHeader
          };
        },
        body: ({ theme }) => {
          return {
            ...theme.typography.cellRegular
          };
        }
      },
      variants: [{
        props: {
          variant: 'bold'
        },
        style: ({ theme }) => ({
          ...theme.typography.cellBold
        })
      }]
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.inputField
        }
        )
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            ...theme.typography.formLabel,
            '& .MuiFormLabel-asterisk': {
              color: theme.palette.primary.main
            }
          };
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            ...theme.typography.inputField,
            background: theme.palette.gray.light05,
            padding: '0rem .75rem',
            border: `1px solid ${theme.palette.gray.light20}`,
            borderRadius: '.25rem',
            '&.MuiInputBase-root': {
              margin: '0px'
            }
          };
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            ...theme.typography.inputHelperText
          };
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: ({ theme }) => {
          return {
            ...theme.typography.button
          };
        },
        outlined: ({ theme }) => {
          return {
            ...theme.typography.button
          };
        },
        containedPrimary: ({ theme }) => {
          return {
            ...theme.typography.button,
            minWidth: 'max-content',
            height: '2.5rem',
            padding: '1rem 1.5rem',
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            '& .MuiButton-icon': {
              stroke: 'white'
            },
            borderRadius: '.25rem'
          };
        },
        containedSecondary: ({ theme }) => {
          return {
            ...theme.typography.button,
            minWidth: 'max-content',
            color: `${theme.palette.primary.main}`,
            '& .MuiButton-icon': {
              stroke: theme.palette.primary.main
            }
          };
        },
        textPrimary: ({ theme }) => {
          return {
            ...theme.typography.textFieldLink,
            color: theme.palette.primary.main,
            minWidth: 'max-content',
            '& .MuiButton-icon': {
              stroke: theme.palette.primary.main
            }
          };
        },
        outlinedPrimary: ({ theme }) => {
          return {
            ...theme.typography.button,
            backgroundColor: theme.palette.primary.light05,
            padding: '1rem 1.5rem',
            minWidth: 'max-content',
            height: '2.5rem',
            color: theme.palette.primary.main,

            border: `1px solid ${theme.palette.primary.main}`,
            '& .MuiButton-icon': {
              stroke: theme.palette.primary.main
            }
          };
        },
        outlinedSecondary: ({ theme }) => {
          return {
            ...theme.typography.button,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.gray.light80,
            boxShadow: `0px 2px 0px 0px ${theme.palette.gray.light30}`,
            border: `1px solid ${theme.palette.gray.light20}`,
            '& .MuiButton-icon': {
              stroke: '#414142'
            }
          };
        },
        outlinedSizeLarge: ({ theme }) => {
          return {
            ...theme.typography.button,
            backgroundColor: theme.palette.primary.light05,
            padding: '1rem 1.5rem',
            height: '3rem',
            color: theme.palette.primary.main,

            border: `1px solid ${theme.palette.primary.main}`,
            '& .MuiButton-icon': {
              stroke: theme.palette.primary.main
            }
          };
        }
      }
    }
  }
});
