import { useBackgroundQuery } from '@apollo/client';
import { GetCountiesForStateDocument } from 'generated/graphql';
import { appFormUtil } from 'modules/common';
import { StyledInputTextField } from 'modules/components/layer01';
import { BaseFormLayout } from 'modules/components/layer14';
import React from 'react';



export type ScenarioDetailsFormProps<T extends object> = {
  form: appFormUtil.InferReturn<T>;
  fields: (keyof appFormUtil.InferReturn<T>['formState'])[];
  pageIndex: keyof appFormUtil.InferReturn<T>['formState'];
};

export const FC = <T extends object>(props: ScenarioDetailsFormProps<T>) => {
  const pageNumber = props.form.formState[props.pageIndex];


  return (
    <BaseFormLayout.FC
      sectionTitle = 'Scenario Details'
      sectionSubtitle = 'Please complete required scenario details and continue'
      pageIndex = {`${pageNumber}`}
    >

      {props.fields.map((field, i) => (
        <StyledInputTextField.FC
          key = {i}
          minRows = {field === 'message' ? 8 : 0}
          {...props.form.genFieldProps(field)}
        />
      ))}

    </BaseFormLayout.FC>
  );
};
