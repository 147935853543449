import { Box } from '@mui/material';
import { NavBar } from 'modules';
import React from 'react';
import { Outlet } from 'react-router-dom';



export type AdminLayoutProps = {};

export const AdminLayout = (props: AdminLayoutProps) => {

  return (
    <Box
      display = 'flex'
      flexDirection = 'row'
    >
      <NavBar.FC />
      <Outlet />
    </Box>
  );
};

