import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React from 'react';
import * as ds from './dataService';
import { ScenariosTableRow } from 'modules/components/layer23';


export type ScenariosTableProps = {};

export const FC = (props: ScenariosTableProps) => {
  const headers = [
    'Name',
    'id',
    'state',
    'counties',
    'description',
    'date created'
  ];

  const { data } = ds.useQuery();
  return (
    <TableContainer>
      <Table
        size = 'small'
      >
        <TableHead>
          <TableRow>
            {headers.map((cell, i) => (
              <TableCell
                size = 'medium'
                key = {i}
                variant = 'head'
              >
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((node, i) => (
              <ScenariosTableRow.FC
                key = {i}
                {...node}
              />
            ))
          }

        </TableBody>
      </Table>
    </TableContainer>
  );
};
