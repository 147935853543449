import { Box } from '@mui/material';
import {
  ActivityHeaderSectionLayout,
  ScenariosTable
} from 'modules';
import React from 'react';



export type ScenariosActivityProps = {};

export const ScenariosActivity = (props: ScenariosActivityProps) => {

  return (
    <Box
      paddingX = '4rem'
      flex = '1 1 0'
    >
      <ActivityHeaderSectionLayout.FC
        title = 'Scenarios'
        itemCount = {214}
      />
      <ScenariosTable.FC />

    </Box>
  );
};
