import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  DialogContent,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box
} from '@mui/material';
import React, { ReactNode } from 'react';



export type BaseModalLayoutProps = {
  isVisible: boolean;
  handleClose: () => void;
  title: string;
  subtitle?: string;
  handleSave?: () => void;
  children: ReactNode | ReactNode[];
};

export const FC = (props: BaseModalLayoutProps) => {

  return (
    <Dialog
      open = {props.isVisible}
      onClose = {props.handleClose}
    >
      <DialogTitle>
        <Stack
          direction = 'row'
          justifyContent = 'space-between'
        >
          <Typography
            variant = 'modalTitle'
          >
            {props.title}
          </Typography>
          <IconButton
            onClick = {props.handleClose}
          >
            <Close />
          </IconButton>
        </Stack>

        { props.subtitle && (
          <Typography
            variant = 'textFieldRegular'
          >
            {props.subtitle}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        {props.children}

      </DialogContent>


    </Dialog>
  );
};
