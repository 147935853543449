import { Box } from '@mui/material';
import { CreateScenarioSection } from 'modules';
import React from 'react';



export type CreateScenarioActivityProps = {};

export const CreateScenarioActivity = (props: CreateScenarioActivityProps) => {

  return (
    <Box>
      <CreateScenarioSection.FC />
    </Box>
  );
};
