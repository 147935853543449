import { faker } from '@faker-js/faker';
import React from 'react';
import {
  EmailScenario_Input,
  useEmailScenarioMutation,
  useGetScenarioDetailQuery
} from 'generated/graphql';

const initialState = {
  name: '',
  preferredEmail: '',
  message: '',
  scenarioId: ''
};



export function useQuery(id: string) {
  const { data } = useGetScenarioDetail(id);
  return {
    initialState: {
      ...initialState,
      scenarioId: data.humanReadableId ?? ''
    }
  };

}

export interface IData {
  id: string;
  humanReadableId: string;
}

export function useGetScenarioDetail(scenarioId: string) {
  const tuple = useGetScenarioDetailQuery({
    variables: {
      scenarioId
    },
    skip: scenarioId === ''
  });

  return React.useMemo(
    () => {
      const data: IData = {
        id: tuple.data?.scenario?.id ?? '',
        humanReadableId: tuple.data?.scenario?.humanReadableID ?? ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}


export function useEmailScenario() {
  const [
    fnMutate,
    tuple
  ] = useEmailScenarioMutation({
    refetchQueries: []
  });

  const fnWrappedMutate = React.useCallback(
    async (input: EmailScenario_Input) => {
      await fnMutate({
        variables: {
          input: {
            ...input
          }
        }
      });
    },
    [fnMutate]
  );

  return React.useMemo(
    () => [
      fnWrappedMutate,
      {
        ...tuple,
        data: null
      }
    ] as const,
    [
      fnWrappedMutate,
      tuple
    ]
  );
}
