import React from 'react';
import { BasicThemeActivity } from 'activities/theme/BasicThemeActivity/BasicThemeActivity';
import { ThemeLayout } from 'activities/theme/ThemeLayout';
import { RouteObject } from 'react-router-dom';
import { ScenarioLayout } from './ScenarioLayout';
import { CreateScenarioActivity } from './CreateScenarioActivity/CreateScenarioActivity';
import { ScenarioDetailActivity } from './ScenarioDetailActivity/ScenarioDetailActivity';



export const root: RouteObject = {
  path: 'scenario',
  element: <ScenarioLayout />,
  children: [
    {
      path: 'create',
      element: <CreateScenarioActivity />
    },
    {
      path: ':scenarioId',
      element: <ScenarioDetailActivity />
    }
  ]
};
