import { useGetCountiesForStateQuery } from 'generated/graphql';
import React from 'react';


export interface IData {
  id: string;
  name: string;
  displayName: string;
}

export function useQuery(stateId: string) {
  const tuple = useGetCountiesForStateQuery({
    variables: {
      options: {
        filter: {
          stateId: stateId
        }
      }
    },
    skip: stateId === ''
  });

  return React.useMemo(
    () => {
      const data: IData[] = tuple.data?.counties.map(county => ({
        id: county.id ?? '',
        name: `${county.id}` ?? '',
        displayName: county.name ?? ''
      })) ?? [{
        id: '', name: '', displayName: ''
      }];

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}

