import { Box } from '@mui/material';
import {
  ActivityHeaderSectionLayout,
  ScenariosTable,
  UserTable
} from 'modules';
import React from 'react';



export type AdminsActivityProps = {};

export const AdminsActivity = (props: AdminsActivityProps) => {

  return (

    <Box
      paddingX = '4rem'
      flex = '1 1 0'
    >
      <ActivityHeaderSectionLayout.FC
        title = 'Adminstrators'
      />
      <UserTable.FC />

    </Box>
  );
};
