import {
  Add,
  Expand
} from '@mui/icons-material';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography
} from '@mui/material';
import React, { useState } from 'react';



export type CardProps = {};

export const FC = (props: CardProps) => {
  const [
    isExpanded,
    setIsExpanded
  ] = useState(true);
  return (
    <Card>
      <CardHeader
        title = 'Jones County'
        action = {(
          <IconButton
            onClick = {() => setIsExpanded(prev => !prev)}
          >
            <Add />
          </IconButton>
        )}
      />
      <Collapse
        in = {isExpanded}
        unmountOnExit
      >
        <CardContent>

          <Typography variant = 'textFieldRegular'>content in the expanded section</Typography>
        </CardContent>
      </Collapse>

    </Card>
  );
};
