import { Container } from '@mui/material';
import { ScenarioBuilderHeader } from 'modules/components/layer05';
import React from 'react';
import { Outlet } from 'react-router-dom';



export type ThemeLayoutProps = {};

export const ThemeLayout = (props: ThemeLayoutProps) => {

  return (
    <>
      <ScenarioBuilderHeader.FC />
      <Container>
        <Outlet />
      </Container>
    </>
  );
};
