import React, { useState } from 'react';
// import countySvgData from '../../../../assets/maps/countySvgData.json';
import { appFormUtil } from 'modules';
import _, {
  isArray,
  update
} from 'lodash';
import * as ds from './dataService';
import {
  Box,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

export type SelectCountiesMapProps<T extends object> = appFormUtil.SelectFieldProps<T>;


export const FC = <T extends object> (props: SelectCountiesMapProps<T> & { stateId: string }) => {
  const { data } = ds.useQuery(props.stateId ?? '');
  const theme = useTheme();


  const counties = props.form.formState[props.fieldName];
  let selectedCounties: string[];
  if (_.isArray(counties)) {
    selectedCounties = counties;
  } else {
    selectedCounties = [];
  }


  return (
    <>
      <Stack>
        {_.isArray(props.form.formState[props.fieldName])
          ? (
            selectedCounties.map((val, i) => {
              const name = data.find(node => node.id === val);

              return (
                <Typography
                  key = {i}
                  variant = 'inputHelperText'
                >{name?.displayName}
                </Typography>
              );
            })
          )
          : (<></>)}
      </Stack>
      <svg
        xmlns = 'http://www.w3.org/2000/svg'
        width = '100%'
        viewBox = '0 0 952.8 1300'
      >{
          data.map((ele, i) => (

            <>
              <path
                d = {ele.svgPath}
                fill = {selectedCounties.includes(ele.name) ? theme.palette.primary.main : '#FFFFFF'}
                onClick = {() => {
                  if (selectedCounties.includes(ele.name)) {
                    props.form.dispatch({
                      type: 'UPDATE',
                      payload: {
                        property: props.fieldName,
                        value: selectedCounties.filter(node => node !== ele.name) as T[keyof T]
                      }
                    });

                  } else {
                    props.form.dispatch({
                      type: 'UPDATE',
                      payload: {
                        property: props.fieldName,
                        value: [
                          ...selectedCounties,
                          ele.name
                        ] as T[keyof T]
                      }
                    });
                  }
                }}

                stroke = '#000'
                strokeWidth = '1'
              />
            </>
          ))
        }

      </svg>
    </>


  );

};
