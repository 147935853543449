import { customPalette } from 'theme/layer00';

export const typography = {
  fontFamily: [
    'Plus Jakarta Sans',
    'Open Sans'
  ].join(''),
  activityHeader: {
    color: `${customPalette.primaryColor.main}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '1.5rem',
    fontWeight: 'normal',
    lineHeight: '2.5rem',
    textTransform: 'none'
  },
  textFieldRegular: {
    color: `${customPalette.gray.darker200}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: 'normal',
    textTransform: 'none',
    letterSpacing: '0.02rem'
  },
  cardHeader: {
    color: `${customPalette.primaryColor.main}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '1.25rem',
    fontWeight: '600',
    lineHeight: '1.5rem',
    textTransform: 'none',
    letterSpacing: '0.02rem'
  },
  textFieldBold: {
    color: `${customPalette.gray.darker200}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.2rem',
    textTransform: 'none',
    letterSpacing: '0.02rem'
  },
  textFieldLink: {
    color: `${customPalette.primaryColor.dark}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: 'normal',
    textTransform: 'none',
    letterSpacing: '0.02rem'
  },
  modalTitle: {
    color: `${customPalette.gray.dark}`,
    fontFamily: 'Open Sans',
    fontSize: '1.75rem',
    fontWeight: '700',
    lineHeight: '2.8rem',
    fontStyle: 'normal',
    textTransform: 'none'
  },
  modalSubTitle: {
    color: `${customPalette.gray.light80}`,
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.4rem',
    fontStyle: 'normal',
    textTransform: 'none'
  },
  modalSectionTitle: {
    color: `${customPalette.gray.darker200}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '-0.03rem'
  },
  modalStepCountTotal: {
    color: `${customPalette.primaryColor.main}`,
    fontFamily: 'Open Sans',
    fontSize: '2rem',
    fontWeight: '700',
    lineHeight: '2.8rem',
    fontStyle: 'normal',
    textTransform: 'none'
  },
  modalStepCountActive: {
    color: `${customPalette.primaryColor.main}`,
    fontFamily: 'Open Sans',
    fontSize: '2rem',
    fontWeight: '300',
    lineHeight: '2.8rem',
    fontStyle: 'normal',
    textTransform: 'none'
  },
  formLabel: {
    color: `${customPalette.gray.darker100}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '.8125rem',
    fontWeight: '600',
    lineHeight: '1rem',
    textTransform: 'none'
  },
  inputField: {
    color: `${customPalette.gray.darker100}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '.9375rem',
    fontWeight: '600',
    lineHeight: '1rem',
    textTransform: 'none',
    letterSpacing: '.00938rem'
  },
  button: {
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '1.4rem',
    fontStyle: 'normal',
    letterSpacing: '.02rem',
    textTransform: 'none'
  },
  inputHelperText: {
    color: `${customPalette.primaryColor.main}`,
    fontFamily: 'Open Sans',
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1rem',
    textTransform: 'none'
  },
  cellBold: {
    color: `${customPalette.gray.main}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '.75rem',
    textTransform: 'uppercase',
    letterSpacing: '0.01375rem'
  },
  cellRegular: {
    color: `${customPalette.gray.main}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '.75rem',
    fontWeight: '400',
    lineHeight: '1.05rem',
    textTransform: 'uppercase',
    letterSpacing: '0.015rem'
  },
  tableTitle: {
    color: `${customPalette.gray.darker200}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '.75rem',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '-0.0225rem'
  },
  cellHeader: {
    color: `${customPalette.gray.main}`,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.0125rem'
  }

} as const;
