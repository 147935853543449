import React from 'react';

import {
  Box,
  Typography,
  Divider,
  Stack
} from '@mui/material';

import {
  SummarySectionLayout,
  SelectedCountyRatesCard,
  CountyRentalRatesTable
} from 'modules';

import * as ds from './dataService';
import { CountyProgram } from 'generated/graphql';
import _ from 'lodash';



export type SelectedCountiesRatesProps = {
  id: string;
};

export const FC = (props: SelectedCountiesRatesProps) => {
  const { data } = ds.useQuery(props.id);



  return (
    <SummarySectionLayout.FC
      sectionName = 'Selected Counties'
    >
      <Stack
        rowGap = '.75rem'
      >
        {data.counties.map((county, i) => {
          const countyPrograms = county.programs?.map(node => {
            const programName = node.practice.code;

            return programName;
          }).filter(node => node !== undefined) ?? [];

          if (county.programs && county.programs.length < 1) {
            return (
              <SelectedCountyRatesCard.FC
                key = {i}
                countyName = {county.name ?? ''}
              >
                <Typography variant = 'modalSectionTitle'>Not Available</Typography>
              </SelectedCountyRatesCard.FC>
            );
          }

          return (
            <SelectedCountyRatesCard.FC
              countyName = {county.name ?? ''}
              key = {i}
            >
              <CountyRentalRatesTable.FC
                countyName = {county.name ?? ''}
                countyPrograms = {county.programs ?? []}
                state = {data.state.abbreviation ?? ''}
                id = {`${i}`}
              />
              {
                county.programs?.map((program, i) => (
                  <Stack
                    key = {i}
                    direction = 'row'

                  >

                    <Typography
                      variant = 'textFieldBold'
                    >
                      {program.practice.code}:
                    </Typography>
                    <Typography
                      key = {i}
                      variant = 'body1'
                    >
                      {program.practice.title}
                    </Typography>
                  </Stack>
                ))
              }
            </SelectedCountyRatesCard.FC>
          );

        })}
      </Stack>

    </SummarySectionLayout.FC>
  );
};



