import { Download } from '@mui/icons-material';
import {
  Box,
  Stack,
  Button
} from '@mui/material';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink
} from '@react-pdf/renderer';
import { LandEnrolledOverTimeCard } from 'modules/components/layer12';
import { BaseFormLayout } from 'modules/components/layer14';
import { ShareScenarioModal } from 'modules/components/layer17';
import {
  ScenarioSummaryDetailSection,
  ScenarioSummaryRateSection
} from 'modules/components/layer34';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as ds from './dataService';
import { theme } from 'theme';
import { DateTime } from 'luxon';
import { useMobileView } from 'modules/common';



export type ScenarioDetailsSectionProps = {
  id: string;
};

export const FC = (props: ScenarioDetailsSectionProps) => {
  // const data = ds.useQuery(props.id);
  const navigate = useNavigate();
  const isMobile = useMobileView.useMobileView();

  const [fnDownloadCsv] = ds.useGetScenarioDataAsCsvQuery(props.id);
  const [
    shareIsVisible,
    setShareIsVisible
  ] = useState(false);
  return (
    <>
      {shareIsVisible && (
        <ShareScenarioModal.FC
          id = {props.id ?? ''}
          isVisible = {shareIsVisible}
          handleClose = {() => {
            setShareIsVisible(() => false);
          }}
        />
      )}
      <BaseFormLayout.FC
        sectionTitle = 'Summary of Your Scenario'
        sectionSubtitle = 'Here are the results of your scenario'
        pageIndex = '3'
        summary = {true}
      >
        <Box
          display = 'flex'
          flexDirection = 'column'
          rowGap = '1.75rem'
          paddingBottom = '2rem'
        >

          <Stack
            columnGap = '1rem'
            direction = 'row'
          >
            <Button
              variant = 'outlined'
              color = 'primary'
              size = 'large'
              startIcon = {<Download />}
              onClick = {async () => {
                await fnDownloadCsv();
              }}
            >
              Download
            </Button>
            <Button
              variant = 'outlined'
              color = 'primary'
              size = 'large'
              startIcon = {<Download />}
              onClick = {async () => {
                window.print();
              }}
            >
              Print
            </Button>

            <Button
              onClick = {() => setShareIsVisible(() => true)}
              variant = 'outlined'
              color = 'primary'
              size = 'large'

              startIcon = {<Download />}
            >
              Share via email
            </Button>

          </Stack>



          <ScenarioSummaryDetailSection.FC
            id = {props.id}
          />

          <ScenarioSummaryRateSection.FC
            id = {props.id}
          />

          {/* <LandEnrolledOverTimeCard.FC /> */}

          <Box
            display = 'flex'
            flexDirection = 'row'
            columnGap = '.75rem'
            rowGap = '.5rem'
            flexWrap = 'wrap'
          >
            <Button
              sx = {{
                flex: '1 1 0'
              }}
              LinkComponent = 'a'
              variant = 'outlined'
              target = '_blank'
              href = 'https://www.crpinfo.com/county-offices'
            >
              Find your Local FSA
            </Button>
            <Button
              sx = {{
                flex: '1 1 0'
              }}
              onClick = {() => navigate('../create')}
              variant = 'contained'
              color = 'primary'
            >
              Create another scenario
            </Button>
          </Box>

        </Box>
      </BaseFormLayout.FC>
    </>
  );
};



// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10
  }


});

interface ICreateScenarioInput {
  id: string;
  name: string;
  message: string;
  readableId: string;
  dateCreated: string;
  state: string;
  selectedCountyNames: string[];
  countyData?: {
    name: string;
    tableRows: string[][];
  }[];

}
// Create Document Component
const MyDocument = (input: ICreateScenarioInput) => (
  <Document>
    <Page size = 'A4' style = {styles.page}>
      <View style = {
        styles.section
      }
      >
        <Text
          style = {{
            color: theme.typography.activityHeader.color
          }}
        >Summary of your Scenario
        </Text>
      </View>
      <View style = {[
        styles.section,
        {
          flex: '3 1 1'
        }
      ]}
      >
        <Text
          style = {{
            // color: theme.typography.textFieldBold.color
          }}
        >Scenario Details
        </Text>
        <Text>Date Created: {DateTime.fromISO(input.dateCreated).toFormat('LLL dd, yyyy')}</Text>
        <Text>Scenario ID: {input.readableId}</Text>
        <Text>
          Scenario Name: {input.name}
        </Text>
        <Text>Message: {input.message}</Text>
        <Text>State: {input.state}</Text>
        <Text>Counties: {input.selectedCountyNames.join(', ')}</Text>
      </View>
      <View>
        {input.countyData?.map(node => {
          const name = <Text>`Rental rates (${node.name}, ${input.state} | General CRP)`</Text>;
          const tableRows = node.tableRows.map((row, i) => (
            <Text
              key = {i}
            >
              {`${row.join(', ')}`}
            </Text>


          ));
          return [
            name,
            tableRows
          ];
        })}
      </View>
    </Page>
  </Document>
);
