import { Theme } from '@mui/material';

export const primaryColor = {
  light05: '#F5F8F7',
  light10: '#E8EFEC',
  light20: '#CFDDD9',
  light40: '#A2C9BE',
  darker100: '#105640',
  main: '#105640',
  light: '#A2C9BE',
  dark: '#046A38',
  darker: '',
  contrastText: 'white'
} as const;

export const gray = {
  light05: '#FCFCFC',
  light10: '#EEEFEF',
  light20: '#DDDEDE',
  darker200: '#313435',
  darker100: '#545859',
  light80: '#707476',
  light40: '#BBBCBD',
  main: '#545859',
  light: '#A2C9BE',
  dark: '#313435',
  darker: '',
  contrastText: 'white'
} as const;

export const orange = {
  main: '#EC8D22'
} as const;



export const palette: Partial<Theme['palette']> = {
  primary: {
    ...primaryColor
  },
  gray: {
    ...gray,
    main: gray.darker100,
    light: gray.light20,
    dark: gray.darker200
  },
  warning: {
    light: orange.main,
    main: orange.main,
    dark: orange.main,
    contrastText: orange.main

  }
} as const;
