import {
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import {
  BaseDialog,
  Buttons,
  Colors,
  CustomCard,
  CustomTable,
  Font,
  FormComponents,
  Map
} from './components';



export type BasicThemeActivityProps = {};

export const BasicThemeActivity = (props: BasicThemeActivityProps) => {
  const [
    isVisible,
    setIsVisible
  ] = useState(false);
  return (
    <Box
      display = 'flex'
      flexDirection = 'column'
    >

      <Typography
        variant = 'h3'
      >
        Theme
      </Typography>
      <Map.FC />
      <Colors.FC />
      <Divider />
      <Font.FC />
      <Divider />
      <Buttons.FC />
      <CustomTable.FC />
      <CustomCard.FC />
      <FormComponents.FC />
      <Button
        onClick = {() => setIsVisible(() => true)}
      >
        Show Modal
      </Button>
      {isVisible && (
        <BaseDialog.FC
          isVisible = {isVisible}
          handleClose = {() => setIsVisible(() => false)}
        />
      )}
    </Box>
  );
};
