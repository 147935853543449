
import {
  Person,
  TableChart,
  VerifiedUser
} from '@mui/icons-material';
import {
  Box,
  CSSObject,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Theme,
  Typography
} from '@mui/material';
import { NavBarItem } from 'modules/components/layer00';
import React, { ReactNode } from 'react';
import { theme } from 'theme';


export type NavBarProps = {};

const drawerWidth = '304px';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  backgroundColor: '#005978'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 7px)`,
  // height: '100vh',
  backgroundColor: '#005978',

  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 7px)`,
    height: '100vh'
  }
});

const LogOutMixin = (theme: Theme): CSSObject => ({
  bottom: 'calc(.5vh)'
});


export const FC = (props: NavBarProps) => {
  const [
    showNav,
    setShowNav
  ] = React.useState(false);


  return (
    <Drawer
      variant = 'permanent'
      open = {showNav}
      sx = {{

        flexShrink: 0,
        ...(showNav && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': {
            ...openedMixin(theme),
            height: '-webkit-fill-available'
          }
        }),
        ...(!showNav && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
          height: '-webkit-fill-available'
        })
      }}
      onMouseOver = {() => setShowNav(() => true)}
      onMouseLeave = {() => setShowNav(() => false)}
    >
      <NavBarItem.FC
        icon = {<TableChart />}
        label = 'Scenarios'
        pathName = 'scenarios'
      />
      <NavBarItem.FC
        icon = {<Person />}
        label = 'Admins'
        pathName = 'admins'
      />
    </Drawer>
  );
};
