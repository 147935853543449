import React from 'react';
import {
  appFormUtil,
  gqlCacheUtil
} from 'modules/common';
import * as types from './types';
import { faker } from '@faker-js/faker';
import {
  GetScenarioDetailDocument,
  GetScenarioDetailQuery,
  SaveScenario_Input,
  SaveScenarioMutation,
  useSaveScenarioMutation
} from 'generated/graphql';
import { DateTime } from 'luxon';

const initialState: types.IScenarioFormState = {
  counties: [],
  county: '',
  id: faker.string.uuid(),
  scenarioId: '',
  message: '',
  name: '',
  pageIndex: 1,
  state: ''
};



export const useQuery = () => {
  const form = appFormUtil.useFormState({
    initialState,
    key2FieldConfig: {
      id: {
        type: 'text'
      },
      scenarioId: {
        type: 'text'
      },
      pageIndex: {
        type: 'number'
      },
      county: {
        label: 'Single County',
        type: 'text',
        required: true
      },
      counties: {
        label: 'Counties',
        type: 'text',
        required: true
      },
      message: {
        label: 'Description',
        type: 'text'
      },
      name: {
        label: 'Scenario Name',
        type: 'text',
        required: true
      },
      state: {
        label: 'Select a state',
        type: 'text',
        required: true
      }
    }
  });
  return {
    form,
    initialState
  };
};

export type SaveScenarioInput = SaveScenario_Input;

export function useSaveScenario() {
  const [
    fnMutate,
    tuple
  ] = useSaveScenarioMutation({
    refetchQueries: []
  });



  const fnWrappedMutate = React.useCallback(
    async (input: SaveScenarioInput) => {
      const resp = await fnMutate({
        variables: {
          input
        },
        optimisticResponse: {
          saveScenario: {
            id: '',
            counties: [...input.counties.map(node => ({
              id: node ?? '',
              fipsCode: 0,
              name: '',
              programs: []
            }))],
            dateCreated: DateTime.now().toFormat('LLL dd, yyyy'),
            humanReadableID: input.humanReadableID ?? '',
            message: input.message ?? '',
            name: input.name ?? '',
            state: {
              id: input.state ?? '',
              name: 'Georgia',
              abbreviation: 'GA'
            }
          }
        },
        updateQueries: {
          ...gqlCacheUtil.genUpdateQueryMap<GetScenarioDetailQuery, SaveScenarioMutation>(
            GetScenarioDetailDocument,
            (prevState: GetScenarioDetailQuery, { mutationResult }) => {
              if (mutationResult.data?.saveScenario) {
                return {
                  ...prevState,
                  scenario: mutationResult.data?.saveScenario
                };
              } else return prevState;
            }
          )
        }
      });
      return resp;
    },
    [fnMutate]
  );

  return React.useMemo(
    () => [
      fnWrappedMutate,
      {
        ...tuple,
        data: {
          id: tuple.data?.saveScenario?.id ?? 'no id'
        }
      }
    ] as const,
    [
      fnWrappedMutate,
      tuple
    ]
  );
}
