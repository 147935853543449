import {
  makeVar,
  useReactiveVar
} from '@apollo/client';

import React from 'react';
import { Outlet } from 'react-router-dom';



export type RootLayoutProps = {};

export const RootLayout = (props: RootLayoutProps) => {
  // useSize.useSize();
  return (
    <Outlet />
  );
};
