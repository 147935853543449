import {
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import React, { ReactNode } from 'react';
import {
  useNavigate,
  useLocation
} from 'react-router-dom';



export type NavBarItemProps = ListItemButtonProps & {
  icon: ReactNode;
  pathName: string;
  label: string;
};

const themedStyles = (theme: Theme, selected: boolean) => {
  return {
    '&.MuiSvgIcon-root': {
      stroke: selected ? theme.palette.primary.main : theme.palette.secondary.main,
      fill: selected ? theme.palette.primary.main : theme.palette.secondary.main
    }
  };
};

export const FC = (props: NavBarItemProps) => {

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const selected = location.pathname === props.pathName ? true : false;
  return (
    <ListItem
      disableGutters
      sx = {{
        backgroundColor: selected ? 'white' : 'transparent',
        borderRadius: '.25rem',
        marginLeft: '10px',
        marginRight: '10px'
      }}
    >
      <ListItemButton
        disableTouchRipple
        disableRipple
        disableGutters
        sx = {{
          paddingLeft: '15px',
          '&.Mui-selected': {
            backgroundColor: 'yellow'
          }

        }}
        onClick = {ev => {
          if (props.onClick) {
            props.onClick(ev);
          } else if (props.pathName) {
            navigate(props.pathName ?? '');
          }
        }}
      >
        <ListItemIcon>
          <SvgIcon
            sx = {themedStyles(theme, selected)}
            inheritViewBox
          >
            {props.icon}
          </SvgIcon>
        </ListItemIcon>

        <ListItemText disableTypography>
          <Typography variant = 'textFieldRegular' color = {selected ? 'black' : 'white'}>
            {props.label}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};
