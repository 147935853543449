import React, { useState } from 'react';
import countySvgData from '../../../../assets/maps/countySvgData.json';


export type mapProps = {};

export const FC = (props: mapProps) => {
  const [
    selectedCounties,
    setSelectedCounties
  ] = useState<string[]>([]);
  const handleClick = (id: string) => {
    if (selectedCounties.find(node => node === id)) {
      setSelectedCounties(prev => {
        return prev.filter(node => node !== id);
      });
    } else {
      setSelectedCounties(prev => [
        ...prev,
        id
      ]);
    }

    console.log(`Click on ${id}`);
  };
  return (
    <svg
      xmlns = 'http://www.w3.org/2000/svg'
      width = '952.8'
      height = '1858'
      viewBox = '0 0 952.8 1858'
    >{
        countySvgData.counties.map((ele, i) => (

          <>
            <path
              d = {ele.path}
              fill = {selectedCounties.includes(ele.name) ? '#e80c00' : '#FFFFFF'}
              onClick = {() => {
                setSelectedCounties(prev => [
                  ...prev,
                  ele.name
                ]);
                handleClick(ele.name);
              }}

              stroke = '#000'
              strokeWidth = '1'
            />
          </>
        ))
      }

    </svg>


  );
};
