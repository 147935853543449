import * as LargeCustomIcon from './LargeCustomIcon';
import * as MediumCustomIcon from './MediumCustomIcon';
import * as SmallCustomIcon from './SmallCustomIcon';


export const CustomIcons = {
  large: LargeCustomIcon,
  medium: MediumCustomIcon,
  small: SmallCustomIcon
};
