import React from 'react';
import { useGetAllStatesQuery } from 'generated/graphql';

export interface IData {
  id: string;
  name: string;
  displayName: string;
}

export function useQuery() {
  const tuple = useGetAllStatesQuery({
    variables: {
      options: {
      },
      countyOptions: {
      }
    },
    returnPartialData: true
  });

  return React.useMemo(
    () => {
      const data: IData[] = tuple.data?.states.map(node => ({
        id: node.id ?? '',
        name: node.id ?? '',
        displayName: node.name
      })).filter(node => node.displayName === 'Georgia') ?? [];

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
