import {
  Box,
  Chip,
  Typography
} from '@mui/material';
import React from 'react';



export type ActivityHeaderSectionLayoutProps = {
  title?: string;
  itemCount?: number;
};

export const FC = (props: ActivityHeaderSectionLayoutProps) => {

  return (
    <Box
      display = 'flex'
      flexDirection = 'row'
    >
      <Typography variant = 'activityHeader'>{props.title ?? 'Default'}</Typography>
      {/* {
        props.itemCount && (
          <Chip
            variant = 'filled'
            color = 'success'
            label = {props.itemCount}
          />
        )
      } */}

    </Box>
  );
};
